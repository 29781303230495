import { useTranslation } from '@/presentation/hooks/useTranslation';
import { CashbackTip } from '@/domain/entities/Cashback';
import { CashbackTipItem } from '../CashbackTip';

import { TipSection, TipSectionTitle } from './style';

type CashbackTipsProps = {
  tips: CashbackTip[];
};

export const CashbackTips = ({ tips }: CashbackTipsProps) => {
  const { t } = useTranslation('cashbackBalance');

  return (
    <TipSection>
      <TipSectionTitle>{t('cashback-tip-title')}</TipSectionTitle>
      <ul>
        {tips.map((item) => (
          <CashbackTipItem
            key={item.question}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </ul>
    </TipSection>
  );
};
