import Translation from '@/presentation/components/Translation';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import {
  ActionBar,
  Background,
  CashbackIcon,
  Content,
  HeaderBalanceSkeleton,
  Title,
  TitleWrapper,
} from './style';

export const HeaderSkeleton = () => {
  const { t } = useTranslation('cashbackBalance');

  return (
    <Background>
      <Content>
        <ActionBar />
        <TitleWrapper>
          <CashbackIcon />
          <Title>
            <Translation hasTag translation={t('header-title')}>
              Meu <span>cashback</span>
            </Translation>
          </Title>
        </TitleWrapper>
      </Content>
      <HeaderBalanceSkeleton />
    </Background>
  );
};
