import { css, styled } from 'styled-components';
import { Colors, Display, MediaQuery, Theme } from '@pelando/components';
import { minimumGridStyle } from '@/presentation/styles/global';
import BaseCashbackIcon from '@/resources/supercashback/cashback-icon.svg?component';
import { Balance } from '../Balance';
import { BalanceSkeleton } from '../Balance/skeleton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`;

export const Background = styled.div`
  background-image: url('/assets/cashbackBalance/header.sm.webp');
  background-color: rgb(${Theme.colors.Brand});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 234px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    background-image: url('/assets/cashbackBalance/header.webp');
    border-radius: 0;
    height: 170px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  height: 100%;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${minimumGridStyle}
    align-items: flex-start;
    justify-content: center;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  min-height: 56px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const ActionBarButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;

  i {
    color: rgb(${Colors.White});
    font-size: 24px;
  }
`;

export const ActionBarLink = styled.div`
  display: flex;

  i {
    color: rgb(${Colors.White});
    font-size: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template:
    'icon'
    'title';
  gap: 8px;
  padding-left: 26px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    grid-template: 'title icon';
    gap: 12px;
    align-items: flex-end;
    padding-left: unset;
  }
`;

export const CashbackIcon = styled(BaseCashbackIcon)`
  grid-area: icon;
  width: 32px;
  height: 32px;
  fill: rgb(${Colors.White});

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 8px;
    width: 28px;
    height: 28px;
  }
`;

export const Title = styled.h1`
  div {
    ${Display}
    grid-area: title;
    color: rgb(${Colors.Porcelain});
    display: flex;
    gap: 4px;

    span {
      color: rgb(${Colors.Honey});
    }

    @media ${MediaQuery.SCREEN_MD_UP} {
      flex-direction: column;
      gap: 0;
    }
  }
`;

const baseHeaderBalanceStyle = css`
  height: 84px;
  width: calc(100% - 32px);
  margin: 0 auto;
  margin-top: -56px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const HeaderBalance = styled(Balance)`
  &&& {
    ${baseHeaderBalanceStyle}
  }
`;

export const HeaderBalanceSkeleton = styled(BalanceSkeleton)`
  &&& {
    ${baseHeaderBalanceStyle}
  }
`;
