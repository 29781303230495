import { createGlobalStyle, css, styled } from 'styled-components';
import {
  FontFamily,
  MediaQuery,
  SetupThemeStyle,
  Theme,
  ColorsCSSVariables,
  ThemeName,
} from '@pelando/components/styles';

export const gridStyle = css`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const minimumGridStyle = css`
  ${gridStyle}
  max-width: ${906 + 64}px;
`;

export const maximumGridStyle = css`
  ${gridStyle}
  max-width: ${1360 + 64}px;
`;

export const VisuallyHidden = styled.span`
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1;
  width: 1;
  margin: -1;
  padding: 0;
  border: 0;
`;

export const KeepDarkModeGlobally = createGlobalStyle`
  body {
    &[data-theme=${ThemeName.DARK}],
    &[data-theme=${ThemeName.LIGHT}] {
      ${ColorsCSSVariables.DARK}
    }

    &[data-theme=${ThemeName.LIGHT_NINJA}],
    &[data-theme=${ThemeName.DARK_NINJA}] {
      ${ColorsCSSVariables.DARK_NINJA}
    }
  }
`;

const GlobalStyle = createGlobalStyle`

  :root {
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-bottom: env(safe-area-inset-bottom);
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }


  * {
    margin: 0;
    padding: 0;
    border: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    /* prevent iOS Safari from increasing font-size automatically */
    /* stylelint-disable-next-line */
    -webkit-text-size-adjust: 100%;
    background: rgb(${Theme.colors.Juliet});
    margin: 0;
    overflow-x: hidden;

    /* antialiased fonts */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    ${SetupThemeStyle}

    @supports (padding-top: env(safe-area-inset-top)) {
      padding-top: var(--safe-area-top);
      padding-bottom: var(--safe-area-bottom);
    }

    font-family: ${FontFamily.DM_SANS};
  
  }

  body, button, input {
    color: rgb(${Theme.colors.Alpha});
  }

  input {
    &:-webkit-autofill {
      -webkit-text-fill-color: rgb(${Theme.colors.Alpha});
    }
  }

  a {
    color: rgb(${Theme.colors.Brand});
  }

  button {
    cursor: pointer;
  }

  .minimumGrid {
    ${gridStyle}
    max-width: ${906 + 64}px;
  }

  .maximumGrid {
    ${gridStyle}
    max-width: ${1360 + 64}px;
  }

  .container-fluid {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
  }

  .grayscale-filter {
    filter: grayscale(100%);
  }
`;

const scrollLock = css`
  overflow: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

export function getCssVarComputedValue(cssVar: string) {
  if (typeof window === 'undefined') return '';

  const style = window.getComputedStyle(document.body);
  const varValue = cssVar.match(/(--)(\w{1,})/gi)?.[0];
  return style.getPropertyValue(varValue || '');
}

export const LockScrollGlobally = createGlobalStyle<{ lock: boolean }>`
  body {
    ${({ lock }) => lock && scrollLock}
  }
`;

export default GlobalStyle;
