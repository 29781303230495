import { styled } from 'styled-components';
import { MediaQuery } from '@pelando/components';
import { minimumGridStyle } from '@/presentation/styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`;

export const Content = styled.div`
  ${minimumGridStyle}
  padding-top: 52px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding-top: 44px;
  }
`;
