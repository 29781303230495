import { useTranslation } from '@/presentation/hooks/useTranslation';

import {
  Info,
  InfoContainer,
  Title,
  Container,
  ValueSkeleton,
  ValueSkeletonWrapper,
  ValueStampSkeleton,
  HelpButtonSkeleton,
  RedeemButtonSkeleton,
} from './style';

type BalanceSkeletonProps = {
  className?: string;
};

export const BalanceSkeleton = ({ className }: BalanceSkeletonProps) => {
  const { t } = useTranslation('cashbackBalance');

  return (
    <Container className={className}>
      <InfoContainer>
        <Info>
          <Title>{t('cashback-balance-available-balance-label')}</Title>
          <HelpButtonSkeleton />
        </Info>
        <ValueSkeletonWrapper>
          <ValueStampSkeleton animate />
          <ValueSkeleton animate />
        </ValueSkeletonWrapper>
      </InfoContainer>
      <RedeemButtonSkeleton animate />
    </Container>
  );
};
