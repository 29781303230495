import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { CashbacksActionType } from './actionTypes';
import { GET_CASHBACKS_QUERY } from './queries/getCashbacks';
import {
  CashbackCache,
  Cashbacks,
  CashbacksResponse,
} from './types/getCashbacks';

export const getCashbacks = createNewEndpoint<
  CashbacksResponse,
  void,
  Cashbacks,
  CashbackCache
>({
  id: CashbacksActionType.GetCashbacks,
  query: GET_CASHBACKS_QUERY,
  method: GraphQlMethods.Get,
  transform: (result) => result.me.cashbacks,
  cacheTransform: ({ data, loading }) => ({
    loading,
    cashbacks: data?.edges,
  }),
});
