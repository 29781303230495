import { CircleInfo } from '@pelando/fontawesome/icons';
import { CashbackTip } from '@/domain/entities/Cashback';
import { Container, TipModalIcon, TipText } from './style';

export const CashbackTipItemModal = ({ question, answer }: CashbackTip) => (
  <Container>
    <TipModalIcon icon={CircleInfo} />
    <TipText>
      <strong>{question}</strong> {answer}
    </TipText>
  </Container>
);
