import { Icon } from '@pelando/components';
import { Clock } from '@pelando/fontawesome/icons';
import { getCurrencyStringByValue } from '@/presentation/services/strings';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import { Container, Info, Store, Value } from './style';

export type CashbackItemProps = {
  store: string;
  date: string;
  value: number;
};

export const CashbackItem = ({ date, store, value }: CashbackItemProps) => {
  const { t } = useTranslation('cashbackBalance');

  const formattedDate = new Date(date).toLocaleDateString();
  const formattedValue = getCurrencyStringByValue(value);

  return (
    <Container>
      <Info>
        <Icon icon={Clock} />
        <div>
          <Store>{store}</Store>
          <p>{t('cashback-item-pending-text')}</p>
          <p>{formattedDate}</p>
        </div>
      </Info>
      <Value>{formattedValue}</Value>
    </Container>
  );
};
