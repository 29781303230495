import { PublishEventFn } from '../../domain/events/types';

export type PelandoEvent = {
  name: 'PelandoEvent';
  payload: PelandoEventDetail;
};

export type PelandoEventDetail = {
  eventName: string;
  payload: Record<string, unknown>;
};

/**
 * Serializes the name and payload value so we can send it on the
 * CustomEvent.detail property. We must serialize it because we
 * want to make sure it can be transmited over network, scripts
 * contexts and other channels by default. By doing that we avoid
 * problems with circular references and trying do dispatch an
 * event with values that will not reach the subscribers
 * (e.g: a DOM reference)
 */
function serializeData(name: string, payload: Record<string, unknown>) {
  return JSON.stringify({ eventName: name, payload });
}

/**
 * Parses the event detail and make sure we can work with our custom
 * type from this point forward.
 *
 * Browsers' CustomEvent has a default `detail` property that can
 * hold anything. Here we make sure that we parse that field into a
 * valid JSON object because we will often receive the values stringified
 * @param event
 * @returns
 */
export function parseEventDetail(event: Event): PelandoEventDetail {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return JSON.parse((event as CustomEvent).detail) as PelandoEventDetail;
}

/**
 * Dispatches an event with a serialized payload on the page's root
 * element. This is our main communication mechanism to inform
 * different (and often disconnected) parts of our application that
 * something happened elsewhere.
 *
 * for more info:
 * quick explanation: https://en.wikipedia.org/wiki/Publish%E2%80%93subscribe_pattern
 * complete: https://martinfowler.com/eaaDev/EventCollaboration.html
 */
function dispatchCustomEvent(name: string, payload = {}) {
  // @TODO: Add JSONable utility type-checking
  const detail = serializeData(name, payload);

  if (typeof window !== 'undefined') {
    window.document.documentElement.dispatchEvent(
      new CustomEvent('PelandoEvent', { detail })
    );
  }
}

/**
 * Publishes a domain event. This helper is already type-safe and should
 * be preferred over calling dispachCustomEvent directly.
 */
export const publishDomainEvent: PublishEventFn = (name, data) => {
  dispatchCustomEvent(name, data);
};
