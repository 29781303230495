import { useTranslation } from '@/presentation/hooks/useTranslation';

import {
  BalanceSectionHeader,
  ContentBalanceSkeleton,
  HelpCenterLinkSkeleton,
  Subtitle,
  Title,
} from './style';

export const BalanceSectionSkeleton = () => {
  const { t } = useTranslation('cashbackBalance');

  return (
    <section>
      <BalanceSectionHeader>
        <div>
          <Title>{t('cashback-balance-title')}</Title>
          <Subtitle>{t('cashback-balance-subtitle')}</Subtitle>
        </div>
        <HelpCenterLinkSkeleton />
      </BalanceSectionHeader>
      <ContentBalanceSkeleton />
    </section>
  );
};
