import { styled } from 'styled-components';
import { Button, H2Heavy, MediaQuery, Theme } from '@pelando/components';
import BaseCashbackIcon from '@/resources/supercashback/cashback-icon.svg?component';

export const TipSection = styled.section`
  display: block;
  text-align: center;
  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 452px;
  }
`;

export const TipSectionTitle = styled.h2`
  ${H2Heavy}
  margin-bottom: 12px;
  color: rgba(${Theme.colors.Victor});
`;

export const HelpCenterButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 343px;
  }
`;

export const CashbackIcon = styled(BaseCashbackIcon)`
  fill: rgb(${Theme.colors.Brand});
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
`;
