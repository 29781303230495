import { GetServerSidePropsContext } from 'next';
import Cookies from 'js-cookie';
import { getAllFlagsOnClientSide } from '@/infra/analytics/sendEventToTrackerProxy';
import { COOKIE_UNLOGGED_ID_KEY } from '@/infra/cookies/consts';
import { getProbablyLoggedCookie } from '@/infra/auth/tokenManagement';
import { SearchPageQueryParams } from '@/presentation/services/urls/search';
import { clearSearchTermFromRouteParam } from '@/presentation/pages/public/Search/helpers';
import env from '../../../config/environment';
import { ClickoutQueryParams } from '../../../presentation/services/urls';

export type ClientInfoMetadata = Record<string, unknown> | undefined;

export const CLIENT_INFO_HEADER_NAME = 'x-sosho-client-info';
export const UNLOGGED_ID_HEADER_NAME = 'x-sosho-unlogged-id';
const UNLOGGED_ID_STORAGE_KEY = 'unlogged_id';

export const LANDING_URL_COOKIE_KEY = 'sosho_landing_url';
const defaultCookiesOptions = {
  path: '/',
  // Set default maxAge to 1 year to avoid cookie being deleted
  expires: 365, // 1 year in days
  sameSite: 'Strict' as const,
  domain: env.PUBLIC_PELANDO_DOMAIN,
};

const landingUrlCookieOptions = {
  path: '/',
  sameSite: 'Strict' as const,
  domain: env.PUBLIC_PELANDO_DOMAIN,
};

/**
 * DO NOT REMOVE/CHANGE WITHOUT CHECKING WITH OUR DATA TEAM FIRST.
 */
export const IGNIS_IDENTIFIER = 'ignis';
export const encodeMetaObject = (meta: ClickoutQueryParams): string =>
  encodeURIComponent(JSON.stringify({ ...meta, sosho_app: IGNIS_IDENTIFIER }));

// see: www.envoyproxy.io/docs/envoy/latest/configuration/http/http_conn_man/headers#x-client-trace-id
export const CLIENT_TRACE_HEADER_NAME = 'x-client-trace-id';
export const getRandomUUID = () => {
  try {
    return crypto.randomUUID();
  } catch (e) {
    return undefined;
  }
};

export const getClientOperationTraceId = (): string | undefined =>
  getRandomUUID();

export const getOrCreateUnloggedId = () => {
  if (typeof window === 'undefined') {
    // We don't act on server side to avoid multiple problems
    return undefined;
  }

  const isLogged = getProbablyLoggedCookie();
  if (isLogged) {
    // We don't care about unloggedIds if the user is probably logged in
    return undefined;
  }

  const localStorageUnloggedId = window.localStorage.getItem(
    UNLOGGED_ID_STORAGE_KEY
  );

  const existingUnloggedId = Cookies.get(COOKIE_UNLOGGED_ID_KEY);

  if (localStorageUnloggedId && !existingUnloggedId) {
    Cookies.set(
      COOKIE_UNLOGGED_ID_KEY,
      localStorageUnloggedId,
      defaultCookiesOptions
    );

    return localStorageUnloggedId;
  }
  if (existingUnloggedId) {
    return existingUnloggedId;
  }

  const unloggedId = `guest_${getRandomUUID()}`;

  Cookies.set(COOKIE_UNLOGGED_ID_KEY, unloggedId, defaultCookiesOptions);

  return unloggedId;
};

export const clearUnloggedId = () => {
  Cookies.remove(COOKIE_UNLOGGED_ID_KEY, defaultCookiesOptions);
};

/**
 * Helper to keep track of the session landing url. There is no "right" way
 * to do it so we use the simples one with cookies.
 */
export const setLandingUrl = () => {
  Cookies.set(
    LANDING_URL_COOKIE_KEY,
    window.location.href,
    landingUrlCookieOptions
  );
};

const getLandingUrl = () => Cookies.get(LANDING_URL_COOKIE_KEY) ?? null;

export const removeEmptyValues = (obj: Record<string, unknown>) =>
  Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(obj).filter(([_, v]) => v != null && v !== '')
  );

const isEmptyObj = (obj: Record<string, unknown>) =>
  Object.keys(obj).length === 0;

/**
 * Tries to parser the umts by wrapping the URL constructor in a try/catch block
 * to avoid errors when receiveing invalid URLs or problematic utms
 * @param landingUrl
 * @returns
 */
const tryToParseUTMs = (landingUrl: string | null) => {
  try {
    const landingUrlParams = new URL(landingUrl || '').searchParams;
    return {
      utmCampaign: landingUrlParams.get('utm_campaign'),
      utmSource: landingUrlParams.get('utm_source'),
      utmMedium: landingUrlParams.get('utm_medium'),
      utmTerm: landingUrlParams.get('utm_term'),
      utmContent: landingUrlParams.get('utm_content'),
    };
  } catch (e) {
    return {};
  }
};

/**
 * Construct the metadata request object for the post operations. We use this
 * as a redundant way to keep track of the client information alongside the
 * event tracking mechanisms.
 *
 * @param extra
 * @returns
 */
export function getClientOperationMetadata(
  extra: Record<string, unknown> = {},
  ignoreFlags?: boolean
) {
  try {
    if (typeof window === 'undefined') {
      // for now we only care about client-side tracking
      return undefined;
    }

    const currentUrl = window.location.href;
    const referrerUrl = window.document.referrer;
    const landingUrl = getLandingUrl();

    const clientName = IGNIS_IDENTIFIER;
    const clientVersion = env.PUBLIC_VERSION;
    const clientUserAgent = window.navigator.userAgent;

    const {
      componentSource,
      componentName,
      componentSourceName,
      ...extraProps
    } = extra;
    const cleanedExtraArgs = removeEmptyValues(extraProps);
    const utms = tryToParseUTMs(landingUrl);

    let latestSearchedTerm = '';
    const flags = ignoreFlags ? {} : getAllFlagsOnClientSide();

    if (typeof window !== 'undefined') {
      latestSearchedTerm = sessionStorage.getItem('latest_searched_term') || '';
    }

    return JSON.stringify(
      removeEmptyValues({
        currentUrl,
        referrerUrl,
        landingUrl,
        clientName,
        clientVersion,
        clientUserAgent,
        componentSource,
        componentSourceName,
        componentName,
        searchTerm: latestSearchedTerm,
        flags,
        ...utms,
        extra: isEmptyObj(cleanedExtraArgs) ? undefined : cleanedExtraArgs,
      })
    );
  } catch (e) {
    return '{}';
  }
}

export function getClientOperationMetadataInBase64(
  extra: Record<string, unknown> = {},
  ignoreFlags?: boolean
) {
  try {
    return encodeURIComponent(
      Buffer.from(
        getClientOperationMetadata(extra, ignoreFlags) || ''
      ).toString('base64')
    );
  } catch (err) {
    return 'conversion to base64 failed';
  }
}

export function getServerSideOperationMetadata({
  req,
  resolvedUrl,
  params,
  query,
}: GetServerSidePropsContext) {
  try {
    const forwardedForHeader = req.headers['x-forwarded-for'];
    const socketRemoteAddress = req.socket.remoteAddress;

    let ip = null;

    if (Array.isArray(forwardedForHeader)) {
      const [firstIp] = forwardedForHeader[0].split(', ');
      ip = firstIp;
    } else if (typeof forwardedForHeader === 'string') {
      const [firstIp] = forwardedForHeader.split(', ');
      ip = firstIp;
    } else if (socketRemoteAddress) {
      ip = socketRemoteAddress;
    }

    const forwardedProtoHeader = req.headers['x-forwarded-proto'];
    const protocol = Array.isArray(forwardedProtoHeader)
      ? forwardedProtoHeader[0]
      : forwardedProtoHeader;
    const protocolOrDefault = protocol || 'http';

    const currentUrl =
      req.headers.host && resolvedUrl
        ? `${protocolOrDefault}://${req.headers.host}${resolvedUrl}`
        : '';
    const referrerUrl = req.headers.referer || '';
    const landingUrl = req.cookies?.[LANDING_URL_COOKIE_KEY] || '';

    const clientName = IGNIS_IDENTIFIER;
    const clientVersion = env.PUBLIC_VERSION;
    const clientUserAgent = req.headers['user-agent'];

    const utms = tryToParseUTMs(landingUrl);

    // TODO: #24549 Alterar para utilizar uma forma mais genérica de passar os parâmetros extras
    const isManualSearch = query[SearchPageQueryParams.ManualSearch] === 'true';
    const isSuggestedSearch =
      query[SearchPageQueryParams.SuggestedSearch] === 'true';

    const extraArgs = {
      [SearchPageQueryParams.ManualSearch]: isManualSearch || null,
      [SearchPageQueryParams.SuggestedSearch]: isSuggestedSearch || null,
    };

    const cleanedExtraArgs = removeEmptyValues(extraArgs);
    const term = (params?.term as string) || '';

    const searchTerm = clearSearchTermFromRouteParam(term);

    const flags = globalThis?.flags || {};

    const metadata = removeEmptyValues({
      currentUrl,
      referrerUrl,
      landingUrl,
      clientName,
      clientVersion,
      clientUserAgent,
      searchTerm,
      ip,
      flags,
      ...utms,
      extra: isEmptyObj(cleanedExtraArgs) ? undefined : cleanedExtraArgs,
    });

    return JSON.stringify(metadata);
  } catch (e) {
    return '{}';
  }
}

export const getDeviceFromUserAgent = (userAgent: string): string => {
  if (userAgent.match(/Android/i)) {
    return `Generic Android`;
  }
  if (userAgent.match(/iPhone|iPad/i)) {
    return 'Apple iOS';
  }
  if (userAgent.match(/Windows/i)) {
    const version = userAgent.match(/Windows NT\s([0-9.]+)/i)?.[1];
    return `Windows ${version}`;
  }
  if (userAgent.match(/Macintosh|Mac OS/i)) {
    const version = userAgent
      .match(/Mac OS X\s([0-9_]+)/i)?.[1]
      .replace(/_/g, '.');
    return `MacOS ${version}`;
  }
  if (userAgent.match(/Linux/i)) {
    return 'Linux';
  }
  return '';
};
