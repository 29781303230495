import { CASHBACK_FRAGMENT_OFFER } from '@/infra/api/fragments/cashback';

export const PAGE_INFO_FRAGMENT = `
  fragment PageInfoFragment on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const OFFSET_BASED_PAGE_INFO_FRAGMENT = `
  fragment OffsetBasedPaginationInfoFragment on OffsetBasedPaginationInfo {
    page
    total
    totalPages
  }
`;

export const COMMENT_FEED_FRAGMENT = `
  fragment CommentFeedFragment on Comment {
    id
    content
    author {
      id
      image {
        id
        url(width: 64, height: 64)
      }
      nickname
      isVerified
    }
  }
`;

export const DEAL_TIMESTAMPS_FRAGMENT = `
  fragment DealTimestampsFragment on OfferTimestamps {
    firstApprovedAt
    approvedAt
    createdAt
    pickedAt
    lastCommentedAt
  }
`;

export const DEAL_FEED_FRAGMENT = `
  fragment DealFeedFragment on Offer {
    id
    commentCount
    couponCode
    discountFixed
    discountPercentage
    freeShipping
    image {
      id
      url(height: 238)
    }
    isLocal
    kind
    price
    sourceUrl
    status
    temperature
    title
    isTracked

    timestamps {
      ...DealTimestampsFragment
    }

    category {
      id
      slug
      title
      communities {
        id
        name
        color
        image
        genderPreference
      }
    }

    store {
      id
      slug
      name
      url
      image {
        id
        url(height: 238)
      }
    }

    user {
      id
      image {
        id
        url(width: 64, height: 64)
      }
      nickname
      isVerified
    }
    ...CashbackFragmentOffer
  }
   ${CASHBACK_FRAGMENT_OFFER}
  ${DEAL_TIMESTAMPS_FRAGMENT}
`;

export const FEED_FRAGMENT = `
  fragment FeedFragment on OfferConnection {
    edges {
      ...DealFeedFragment

      lastComment {
        ...CommentFeedFragment
      }
    }

    pageInfo {
      ...PageInfoFragment
    }

    offsetBasedPageInfo {
      ...OffsetBasedPaginationInfoFragment
    }
  }

  ${DEAL_FEED_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${COMMENT_FEED_FRAGMENT}
  ${OFFSET_BASED_PAGE_INFO_FRAGMENT}
`;

export const RECOMMENDED_FEED_FRAGMENT = `
  fragment RecommendedFeedFragment on OfferRecommendationConnection {
    edges {
      ...DealFeedFragment

      lastComment {
        ...CommentFeedFragment
      }
    }

    pageInfo {
      ...PageInfoFragment
    }
  }

  ${COMMENT_FEED_FRAGMENT}
  ${DEAL_FEED_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
