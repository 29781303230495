import {
  AlgoliaEvents,
  GeneralDealEvents,
  GeneralProductEvents,
  GenericAnalyticsPayload,
  SearchEvents,
} from '../../domain/events/analytics';
import env from '../../config/environment';
import { getSavedUserId } from '../auth/tokenManagement';
import { FederatedSearchContext } from '../api/search/types/FederatedSearch';
import { getQueryId } from '../api/search/utils/trackingEvents';

const algoliaEvents: string[] = [
  GeneralProductEvents.ClickToGoToProductDetails,
  GeneralDealEvents.ClickToGoToDealDetails,
  GeneralDealEvents.ClickToGoToStorePage,
  SearchEvents.ViewDealsResults,
  SearchEvents.ViewProductsResults,
  SearchEvents.ViewStoresResults,
  AlgoliaEvents.ClickOut,
];

const isSearchPage = (path: string) => {
  const searchPageRegex = /\/busca/gi;
  return searchPageRegex.test(path);
};

const isDealOrProductOrStorePage = (path: string) => {
  const dealOrProductPageRegex =
    /\/(d|p|cupons-de-descontos|promo-codes)\/(.){1,}/gi;
  return dealOrProductPageRegex.test(path);
};

export function hasPassedThroughSearch(): boolean {
  if (typeof window === 'undefined') return false;
  return window.pathsStack?.some((path) => isSearchPage(path)) || false;
}

export const cameFromSearch = (): boolean => {
  if (typeof window === 'undefined') return false;

  const currentPath = window.pathsStack?.[0] || '';
  const previousPath = window.pathsStack?.[1] || '';
  const beforePreviousPath = window.pathsStack?.[2] || '';

  return (
    isSearchPage(currentPath) ||
    (isDealOrProductOrStorePage(currentPath) &&
      (isSearchPage(previousPath) || isSearchPage(beforePreviousPath)))
  );
};

export const handleAlgoliaEvents = (event: GenericAnalyticsPayload) => {
  if (!cameFromSearch() || !algoliaEvents.includes(event.name)) return;

  const index = ((): 'offers' | 'products' | 'stores' => {
    if (event.name === AlgoliaEvents.ClickOut) {
      return event.extra?.product_id ? 'products' : 'offers';
    }

    return (
      {
        [SearchEvents.ViewDealsResults]: 'offers',
        [GeneralDealEvents.ClickToGoToDealDetails]: 'offers',
        [SearchEvents.ViewProductsResults]: 'products',
        [GeneralProductEvents.ClickToGoToProductDetails]: 'products',
        [SearchEvents.ViewStoresResults]: 'stores',
        [GeneralDealEvents.ClickToGoToStorePage]: 'stores',
      } as Record<string, 'offers' | 'products' | 'stores'>
    )[event.name];
  })();

  const objectIDs = (
    {
      [SearchEvents.ViewDealsResults]: (event.extra?.deals_ids ||
        []) as string[],
      [GeneralDealEvents.ClickToGoToDealDetails]: [
        event.extra?.deal_id,
      ] as string[],

      [SearchEvents.ViewStoresResults]: (event.extra?.stores_ids ||
        []) as string[],
      [GeneralDealEvents.ClickToGoToStorePage]: [event.extra?.id] as string[],

      [SearchEvents.ViewProductsResults]: (event.extra?.products_ids ||
        []) as string[],
      [GeneralProductEvents.ClickToGoToProductDetails]: [
        event.extra?.product_id,
      ] as string[],
      [AlgoliaEvents.ClickOut]: [
        event.extra?.product_id || event.extra?.deal_id,
      ] as string[],
    } as Record<string, string[]>
  )[event.name];

  const positions = event.extra?.index_item
    ? [event.extra.index_item as number]
    : undefined;

  const eventType = {
    [AlgoliaEvents.ClickOut]: 'conversion',
    [GeneralProductEvents.ClickToGoToProductDetails]: 'click',
    [GeneralDealEvents.ClickToGoToDealDetails]: 'click',
    [GeneralDealEvents.ClickToGoToStorePage]: 'click',
    [SearchEvents.ViewDealsResults]: 'view',
    [SearchEvents.ViewProductsResults]: 'view',
    [SearchEvents.ViewStoresResults]: 'view',
  }[event.name as string] as 'conversion' | 'click' | 'view';

  const queryID = {
    products: getQueryId(FederatedSearchContext.Product),
    offers: getQueryId(FederatedSearchContext.Deal),
    stores: getQueryId(FederatedSearchContext.Store),
  }[index];

  if (!eventType || !positions) return;

  const cleanedName = event.name.replace('algolia_', '');

  window.aa?.('sendEvents', [
    {
      eventType,
      eventName: cleanedName,
      index,
      objectIDs,
      userToken: getSavedUserId(),
      queryID,
      positions,
    },
  ]);
};

export const initAlgolia = () => {
  import('search-insights')
    .then((module) => module.default)
    .then((algolia) => {
      window.aa = algolia;
      algolia('init', {
        appId: env.PUBLIC_ALGOLIA_APPID,
        apiKey: env.PUBLIC_ALGOLIA_API_KEY,
      });
    });
};
