import { useTranslation } from '@/presentation/hooks/useTranslation';
import { CashbackTip } from '@/domain/entities/Cashback';
import { getPelandoHelpCenterUrl } from '@/presentation/services/urls';
import { useRouter } from 'next/router';
import {
  CashbackIcon,
  HelpCenterButton,
  TipSection,
  TipSectionTitle,
} from './style';
import { CashbackTipItemModal } from './CashbackTipItemModal';

type CashbackTipsModalProps = {
  tips: CashbackTip[];
};

export const CashbackTipsModal = ({ tips }: CashbackTipsModalProps) => {
  const { t } = useTranslation('cashbackBalance');
  const { push } = useRouter();

  const handleClickHelpCenter = () => push(getPelandoHelpCenterUrl());

  return (
    <TipSection>
      <CashbackIcon />
      <TipSectionTitle>{t('cashback-modal-tip-title')}</TipSectionTitle>
      <ul>
        {tips.map((item) => (
          <CashbackTipItemModal
            key={item.question}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </ul>
      <HelpCenterButton light onClick={handleClickHelpCenter}>
        {t('cashback-modal-help-center-button')}
      </HelpCenterButton>
    </TipSection>
  );
};
