export enum DatePeriod {
  TODAY = 'TODAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum OfferSortOption {
  COMMENT = 'COMMENT',
  CREATED_AT = 'CREATED_AT',
  TEMPERATURE = 'TEMPERATURE',
  RELEVANCE = 'RELEVANCE',
}

export enum ProductSortOptions {
  Recents,
  PriceLowToHigh,
  PriceHighToLow,
}

export type FilterOptions = {
  hideExpired: boolean;
  hottestFeedPeriod: DatePeriod;
  sortBy: OfferSortOption;
};
