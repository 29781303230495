import { styled, css } from 'styled-components';
import {
  BodyLight,
  H1Heavy,
  MediaQuery,
  Skeleton,
  Theme,
} from '@pelando/components';
import { Balance } from '../Balance';
import { BalanceSkeleton } from '../Balance/skeleton';

export const Title = styled.h2`
  ${H1Heavy}
  margin-bottom: 4px;
`;

export const Subtitle = styled.p`
  ${BodyLight}
`;

export const BalanceSectionHeader = styled.div`
  display: none;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
  }
`;

const baseContentBalanceStyle = css`
  display: none;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
  }
`;

export const ContentBalance = styled(Balance)`
  &&& {
    ${baseContentBalanceStyle}
  }
`;

export const ContentBalanceSkeleton = styled(BalanceSkeleton)`
  &&& {
    ${baseContentBalanceStyle}
  }
`;

export const HelpCenterLink = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  cursor: pointer;

  i {
    font-size: 24px;
    color: rgb(${Theme.colors.Alpha});
  }
`;

export const HelpCenterLinkSkeleton = styled(Skeleton)`
  &&& {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
  }
`;
