export const DUPELANDO_SESSION_KEY = 'came_from_dpl';

export function saveDupelandoOriginFlag() {
  if (typeof window === 'undefined') return; // prevent break on server
  window.sessionStorage.setItem(DUPELANDO_SESSION_KEY, 'true');
}

export function getDupelandoOriginFlag(): boolean {
  if (typeof window === 'undefined') return false; // prevent break on server
  return window.sessionStorage.getItem(DUPELANDO_SESSION_KEY) === 'true';
}
