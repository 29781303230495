import { FeaturedOffer, OfferStatus } from '@/domain/entities/Offer';
import { Categories } from './categories';

export const ALLOWED_COMMENT_TAGS = [
  'blockquote',
  'p',
  'em',
  'strong',
  'i',
  'b',
  'br',
  'a',
  'ul',
  'ol',
  'li',
  'img',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  's',
];

export const ALLOWED_DESCRIPTION_TAGS = [...ALLOWED_COMMENT_TAGS, 'div'];

export const debounce = (callback: () => void, time: number) => {
  let timerID: number | undefined;

  return () => {
    clearTimeout(timerID);
    timerID = window.setTimeout(() => {
      callback();
    }, time);
  };
};

export const generateIndexArray = (length: number) =>
  Array.from(Array(length).keys());

export const copyToClipboard = (value: string | null) => {
  const textField = document.createElement('textarea');

  textField.innerText = value || '';
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

/* eslint-disable  no-promise-executor-return */
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const dateToUTCISOString = (date: Date) => {
  const utcString = date.toUTCString();
  const utcDate = new Date(utcString);
  return utcDate.toISOString();
};

export const dateToLocaleISOString = (date: Date) => {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  return iso;
};

export const removeSecondsFromISOString = (iso: string) => iso.slice(0, 16);

export function deduplicate<List extends Iterable<string | number | boolean>>(
  list: List
) {
  const listSet = new Set(list);
  return Array.from(listSet.values()) as unknown as List;
}

export function filterExpiredOffers(array: FeaturedOffer[]) {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (array[i].status !== OfferStatus.ACTIVE) {
      array.splice(i, 1);
    }
  }
}

export function filterOffersByCommunities(
  offers?: FeaturedOffer[],
  cachedOffers?: FeaturedOffer[],
  communitiesIds?: string[]
) {
  if (!offers || !cachedOffers || !communitiesIds) return;

  const communitiesSet = new Set(communitiesIds);

  const filterFunction = (offer: FeaturedOffer) =>
    offer.category?.communities.some(
      (community) => !communitiesSet.has(community.id)
    );

  for (let i = offers.length - 1; i >= 0; i -= 1) {
    if (filterFunction(offers[i])) {
      offers.splice(i, 1);
    }
  }

  for (let i = cachedOffers.length - 1; i >= 0; i -= 1) {
    if (filterFunction(cachedOffers[i])) {
      cachedOffers.splice(i, 1);
    }
  }
}

export function redirectToCommunity(origem: Categories) {
  switch (origem) {
    case Categories.Book:
      return { destination: '/cultura', statusCode: 301 };
    case Categories.Sports:
      return { destination: '/esporte-e-vida', statusCode: 301 };
    case Categories.Game:
      return { destination: '/mundo-gamer', statusCode: 301 };
    case Categories.Fashion:
      return { destination: '/para-elas', statusCode: 301 };
    case Categories.HealthAndBeauty:
      return { destination: '/para-eles', statusCode: 301 };
    case Categories.Automotive:
    case Categories.Home:
    case Categories.Service:
    case Categories.Tools:
    case Categories.Finances:
    case Categories.Internet:
    case Categories.Market:
      return { destination: '/para-meu-lar', statusCode: 301 };
    case Categories.Baby:
      return { destination: '/para-minha-familia', statusCode: 301 };
    case Categories.Eletronics:
      return { destination: '/tech-lover', statusCode: 301 };
    case Categories.Free:
      return { destination: '/tudo-gratis', statusCode: 301 };
    case Categories.Trips:
      return { destination: '/', statusCode: 301 };
    default:
      return { destination: '/', statusCode: 301 };
  }
}
