import { FlagsTypes } from '@/infra/unleash';
import env from '../../config/environment';
import { EventCategory, EventName } from '../../domain/events/analytics';
import { logger } from '../../server/utils/logger';
import {
  CLIENT_INFO_HEADER_NAME,
  CLIENT_TRACE_HEADER_NAME,
  UNLOGGED_ID_HEADER_NAME,
  getClientOperationMetadata,
  getClientOperationTraceId,
  getOrCreateUnloggedId,
} from '../api/core/tracker';
import {
  createAuthHeaderWithLocalToken,
  getSavedUserId,
} from '../auth/tokenManagement';

export type EventPayload = {
  name: string;
  userId: string;
  properties: Record<string, unknown>;
};

/**
 * Sends an event to our proxy endpoint. This will forward it to any tracking tool we might want to use.
 * This way we can change the tool we use without having to change the code and there is no need to install
 * a new library for each tool.
 *
 * @param name - Name of the event
 * @param category - Category of the event
 * @param extra - Additional data to be sent with the event
 */
export async function sendEventToTrackerProxy(
  name: string,
  category: string,
  properties: Record<string, unknown> = {}
) {
  const metadataPayload = getClientOperationMetadata({});
  const metadataHeaders = { [CLIENT_INFO_HEADER_NAME]: metadataPayload || '' };
  const traceHeaders = {
    [CLIENT_TRACE_HEADER_NAME]: getClientOperationTraceId() || '',
    [UNLOGGED_ID_HEADER_NAME]: getOrCreateUnloggedId() || '',
  };
  const headers = {
    'content-type': 'application/json',
    ...createAuthHeaderWithLocalToken(),
    ...traceHeaders,
    ...metadataHeaders,
  };

  const userId = getSavedUserId();

  const body = {
    name,
    category,
    properties,
    ...(userId ? { userId } : {}),
  };

  // The feature flag early return asserts that the env variable is defined
  fetch(env.PUBLIC_EVENT_TRACKING_URL as string, {
    method: 'post',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  })
    .then((res) => res.json() as Promise<{ data?: Response; errors?: Error[] }>)
    .catch((err) => {
      logger.error('Could not send event', err);
    });
}

/**
 * Proxy the current event to the appropriate tracker strategy
 */
export function handleTrackerEvents(
  name: EventName,
  category: EventCategory,
  extra: { [key: string]: unknown } | undefined
) {
  sendEventToTrackerProxy(name, category, extra);
}

export function handlePageView(
  path: string,
  extra?: { [key: string]: unknown } | undefined
) {
  sendEventToTrackerProxy('page_view', 'general', {
    screenPath: path,
    screenTitle: window.document.title,
    screenLocation: window.location.href,
    ...extra,
  });
}

export const getAllFlagsOnClientSide = () =>
  ({
    ...(window?.flags || {}),
    ...(window?.featureToggles || {}),
  } as FlagsTypes);
