import { LoggedUser } from '../../../domain/entities/User';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { AuthActionType } from './actionTypes';
import { AGREE_WITH_LAST_PRIVACY_TERMS_MUTATION } from './mutations/AgreeWithLastPrivacyTerms';
import { SEND_EMAIL_CONFIRMATION_MUTATION } from './mutations/SendEmailConfirmation';
import { LOGIN_MUTATION } from './mutations/login';
import { LOGOUT_MUTATION } from './mutations/logout';
import { SIGNUP_MUTATION } from './mutations/signUp';
import { UPDATE_SIGNUP_METADATA_MUTATION } from './mutations/updateSignUpMetadata';
import AUTH_PROVIDERS_QUERY from './queries/authProviders';
import PRIVATE_USER_QUERY from './queries/privateUser';
import {
  AgreeWithLastPrivacyTermsResponse,
  AgreeWithLastPrivacyTermsVariables,
} from './types/AgreeWithLastPrivacyTerms';
import {
  GetAuthProvidersResponse,
  GetAuthProvidersVariables,
} from './types/GetAuthProviders';
import {
  AuthenticationStatus,
  GetLoggedUserResponse,
  GetLoggedUserVariables,
  LoggedUserCache,
} from './types/GetLoggedUser';
import { LoginResponse, LoginVariables } from './types/Login';
import { LogoutResponse, LogoutVariables } from './types/Logout';
import { SendEmailConfirmationResponse } from './types/SendEmailConfirmation';
import { SignUpResponse, SignUpVariables } from './types/SignUp';
import {
  UpdateSignUpMetadataResponse,
  UpdateSignUpMetadataVariables,
} from './types/UpdateSignUpMetadata';
import {
  RequestPasswordResetMutationVariables,
  RequestPasswordResetResponse,
} from './types/RequestPasswordReset';
import { REQUEST_PASSWORD_MUTATION } from './mutations/requestPasswordReset';
import { UPDATE_PASSWORD_MUTATION } from './mutations/updatePasswordMutation';
import { RequestAccountDeletionResponse } from './types/RequestAccountDeletion';
import { REQUEST_ACCOUNT_DELETION_MUTATION } from './mutations/requestAccountDeletion';
import { UPDATE_EMAIL_MUTATION } from './mutations/updateEmailMutation';
import {
  UpdatePassword,
  UpdatePasswordResponse,
  UpdatePasswordVariables,
} from './types/UpdatePassword';
import {
  UpdateEmail,
  UpdateEmailMutationVariables,
  UpdateEmailResponse,
} from './types/UpdateEmail';

export const requestPasswordReset = createNewEndpoint<
  RequestPasswordResetResponse,
  RequestPasswordResetMutationVariables,
  boolean
>({
  id: AuthActionType.RequestPasswordReset,
  query: REQUEST_PASSWORD_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.public.requestPasswordReset,
});

export const updatePassword = createNewEndpoint<
  UpdatePasswordResponse,
  UpdatePasswordVariables,
  UpdatePassword
>({
  id: AuthActionType.UpdatePassword,
  query: UPDATE_PASSWORD_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.public.updatePassword,
});

export const requestAccountDeletion = createNewEndpoint<
  RequestAccountDeletionResponse,
  void,
  boolean
>({
  id: AuthActionType.RequestAccountDeletion,
  query: REQUEST_ACCOUNT_DELETION_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.requestAccountDeletion,
});

export const updateEmail = createNewEndpoint<
  UpdateEmailResponse,
  UpdateEmailMutationVariables,
  UpdateEmail
>({
  id: AuthActionType.UpdateEmail,
  query: UPDATE_EMAIL_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.updateEmail,
});

export const sendEmailConfirmation = createNewEndpoint<
  SendEmailConfirmationResponse,
  undefined | void,
  boolean
>({
  id: AuthActionType.UpdateEmail,
  query: SEND_EMAIL_CONFIRMATION_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.requestEmailConfirmationEmail,
});

export const agreeWithLastPrivacyTerms = createNewEndpoint<
  AgreeWithLastPrivacyTermsResponse,
  AgreeWithLastPrivacyTermsVariables,
  boolean
>({
  id: AuthActionType.AgreeWithLastPrivacyTerms,
  query: AGREE_WITH_LAST_PRIVACY_TERMS_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) =>
    response.me?.agreeWithPrivacyPolicyTerms?.agreedWithPrivacyPolicy,
});

export const updateSignUpMetadata = createNewEndpoint<
  UpdateSignUpMetadataResponse,
  UpdateSignUpMetadataVariables,
  boolean
>({
  id: AuthActionType.UpdateSignUpMetadata,
  query: UPDATE_SIGNUP_METADATA_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.updateSignupMetadata,
});

export const signUp = createNewEndpoint<
  SignUpResponse,
  SignUpVariables,
  string
>({
  id: AuthActionType.SignUp,
  query: SIGNUP_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.public.signup.token,
});

export const getAuthProviders = createNewEndpoint<
  GetAuthProvidersResponse,
  GetAuthProvidersVariables,
  string[]
>({
  id: AuthActionType.GetAuthProviders,
  query: AUTH_PROVIDERS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.authProviders,
});

export const getLoggedUser = createNewEndpoint<
  GetLoggedUserResponse,
  GetLoggedUserVariables,
  LoggedUser,
  LoggedUserCache
>({
  id: AuthActionType.GetLoggedUser,
  query: PRIVATE_USER_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.me.getUserInfo,
  cacheTransform: ({ data, loading, error }) => {
    const status = (() => {
      if (error) return AuthenticationStatus.UNLOGGED;
      if (loading) return AuthenticationStatus.PENDING;
      if (!data) return AuthenticationStatus.UNLOGGED;
      return AuthenticationStatus.LOGGED;
    })();

    return {
      loading,
      status,
      loggedUser: data,
    };
  },
});

export const logout = createNewEndpoint<
  LogoutResponse,
  LogoutVariables,
  boolean,
  void
>({
  id: AuthActionType.Logout,
  query: LOGOUT_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.logout,
  interceptors: [
    ({ incoming: { loading } }) => {
      getLoggedUser.cache$.next({
        loading,
        status: AuthenticationStatus.UNLOGGED,
      });
    },
  ],
});

export const login = createNewEndpoint<LoginResponse, LoginVariables, string>({
  id: AuthActionType.Login,
  query: LOGIN_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.public.login.token,
});
