import styled from 'styled-components';
import {
  BodyHeavy,
  BodyLight,
  Button,
  CaptionHeavy,
  CaptionLight,
  H1Heavy,
  MediaQuery,
  Skeleton,
  TextSkeleton,
  Theme,
} from '@pelando/components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: rgb(${Theme.colors.India});
  border: 1px solid rgb(${Theme.colors.Bravo});

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 16px 26px;
  }
`;

export const RedeemButton = styled(Button)`
  ${CaptionHeavy}
  max-width: 108px;
  cursor: not-allowed;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${BodyHeavy}
    max-width: 126px;
  }
`;

export const RedeemButtonSkeleton = styled(Skeleton)`
  &&& {
    width: 108px;
    min-width: 108px;
    border-radius: 36px;
    height: 34px;
    min-height: 34px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      width: 116px;
      min-width: 116px;
      height: 36px;
      min-height: 36px;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Info = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Title = styled.h3`
  ${BodyHeavy}
`;

export const Price = styled.span`
  ${BodyLight}

  color: rgb(${Theme.colors.Brand});

  strong {
    ${H1Heavy}
  }
`;

export const HelpButton = styled.button`
  position: relative;
  z-index: 2;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    font-size: 20px;
  }
`;

export const HelpButtonSkeleton = styled(Skeleton)`
  &&& {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
  }
`;

export const TooltipContent = styled.div`
  ${CaptionLight}

  color: rgb(${Theme.colors.Juliet});
`;

export const ValueSkeletonWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
`;

export const ValueSkeleton = styled(TextSkeleton)`
  &&& {
    height: 36px;
    width: 64px;
    min-width: 64px;
    min-height: 36px;
  }
`;

export const ValueStampSkeleton = styled(TextSkeleton)`
  &&& {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }
`;
