export const CASHBACK_FRAGMENT_OFFER = `
  fragment CashbackFragmentOffer on Offer {
    cashback {
        percentage
    }
  }
`;

export const CASHBACK_FRAGMENT_PRODUCT_OFFER = `
  fragment CashbackFragmentProductOffer on ExternalOfferV1 {
    cashback {
        percentage
    }
  }
`;
