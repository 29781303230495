import { CaptionLight, Icon, Theme } from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  border-radius: 12px;
  min-height: 48px;
  width: 100%;
  background-color: rgba(${Theme.colors.Juliet});
  gap: 8px;
`;

export const TipModalIcon = styled(Icon)`
  color: rgb(${Theme.colors.Brand});
  font-size: 24px;
`;

export const TipText = styled.span`
  display: block;
  text-align: justify;
  ${CaptionLight}
  color: rgba(${Theme.colors.Victor})
`;
