import env from '../../config/environment';

export const GA_TRACKING_ID = env.PUBLIC_GA_KEY;

type AnomObject = { [key: string]: unknown };
type WindowWithGA = Window &
  typeof globalThis & {
    gtag: (context: string, id?: string, parameters?: AnomObject) => void;
  };

export function config() {
  (window as WindowWithGA).gtag?.('config', GA_TRACKING_ID);
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const triggerPageview = ({
  title,
  location,
  path,
}: {
  path: string;
  title?: string;
  location?: string;
}) => {
  (window as WindowWithGA).gtag?.('event', 'page_view', {
    page_path: path,
    page_title: title,
    page_location: location,
    send_to: GA_TRACKING_ID,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const triggerEvent = ({
  action,
  category,
  label,
  ...otherValues
}: {
  action: string;
  category: string;
  label?: string;
  [key: string]: unknown;
}) => {
  (window as WindowWithGA).gtag?.('event', action, {
    event_category: category,
    event_label: label,
    ...otherValues,
  });
};
