import env from '@/config/environment';
import pino from 'pino';

const SERVICE_NAME = 'ignis-prod';

export const logger = pino({
  browser: { write: () => {} },
  messageKey: 'message',
  errorKey: 'error',
  mixin: () => ({
    serviceName: SERVICE_NAME,
    version: env.PUBLIC_VERSION,
  }),
});

type LogPerformanceParams = {
  source: string;
  time: number;
  extra?: Record<string, unknown>;
};

export function logPerformance({ source, time, extra }: LogPerformanceParams) {
  logger.info({ source, duration: time, extra });
}

type LogErrorParams = {
  source: string;
  error: Error;
  extra?: Record<string, unknown>;
};

export function logError({ source, error, extra }: LogErrorParams) {
  logger.error({ source, error, extra });
}
