import { mergeWith, Observable, startWith, Subject, tap } from 'rxjs';

import { gqlFetch, GraphQlMethods } from '../fetch';
import {
  errorMessage,
  loadingMessage,
  ResponseResult,
  successMessage,
} from './mapMessages';

import env, { serverConfigKeys } from '../../../../config/environment';
import { logOnReduxDevtools } from '../debug';
import { ClientInfoMetadata } from '../tracker';

export type { ResponseResult };

export type CreateRequestParams = {
  id: string;
  query: string;
  method?: GraphQlMethods;
};

export function createRequest<Response, Variables>({
  id,
  query,
  method = GraphQlMethods.Get,
}: CreateRequestParams) {
  return (
    variables: Variables,
    metadata?: ClientInfoMetadata,
    extraHeader?: { [key: string]: string },
    isServerRequest?: boolean
  ) => {
    const publisher$ = new Subject<ResponseResult<Response, Variables>>();

    return publisher$.pipe(
      startWith(loadingMessage(variables)),
      tap((message) =>
        logOnReduxDevtools({
          type: `${id}/FETCH/LOADING`,
          payload: message,
        })
      ),
      mergeWith(
        new Observable<ResponseResult<Response, Variables>>((subscriber) => {
          gqlFetch<Variables, Response>({
            id,
            baseUrl: !isServerRequest
              ? (env.PUBLIC_API_BASE_URL as string)
              : (serverConfigKeys.API_CLUSTER_BASE_URL as string),
            method,
            query,
            variables,
            extraHeader,
            metadata,
          })
            .then(({ errors, data }) => {
              if (errors) {
                const message = errorMessage(errors[0], variables);
                logOnReduxDevtools({
                  type: `${id}/FETCH/ERROR`,
                  payload: message,
                });

                subscriber.next(message);
                return;
              }
              const message = successMessage(data, variables);
              logOnReduxDevtools({
                type: `${id}/FETCH/SUCCESS`,
                payload: message,
              });

              subscriber.next(message);
            })
            .catch((error) => {
              const message = errorMessage(error, variables);
              logOnReduxDevtools({
                type: `${id}/FETCH/ERROR`,
                payload: message,
              });
              subscriber.next(message);
            })
            .finally(() => {
              subscriber.complete();
              publisher$.complete();
            });
        })
      )
    );
  };
}
