import { useCallback, useId, useRef, useState } from 'react';
import { Icon } from '@pelando/components';
import { CircleInfo } from '@pelando/fontawesome/icons';
import { Tooltip } from '@pelando/components/components/Tooltip/index';
import {
  FloatingElementHorizontalPosition,
  FloatingElementVerticalPosition,
} from '@pelando/components/components/FloatingElementWrapper/consts';
import Translation from '@/presentation/components/Translation';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import {
  Info,
  InfoContainer,
  Title,
  Container,
  RedeemButton,
  Price,
  HelpButton,
  TooltipContent,
} from './style';

type BalanceProps = {
  className?: string;
};

const TOOL_TIP_OPEN_TO = {
  vertical: FloatingElementVerticalPosition.BOTTOM,
  horizontal: FloatingElementHorizontalPosition.MIDDLE,
};

export const Balance = ({ className }: BalanceProps) => {
  const id = useId();
  const { t } = useTranslation('cashbackBalance');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const helpButtonRef = useRef<HTMLButtonElement>(null);

  const handleHelpButtonClick = useCallback(() => {
    setIsTooltipVisible((prev) => !prev);
  }, []);

  const handleHelpButtonBlur = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  const handleHelpButtonKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsTooltipVisible(false);
    }
  }, []);

  const tooltipId = `cashback-balance-info-tooltip-${id}`;

  return (
    <Container className={className}>
      <InfoContainer>
        <Info>
          <Title>{t('cashback-balance-available-balance-label')}</Title>
          <Tooltip
            openTo={TOOL_TIP_OPEN_TO}
            childrenRef={helpButtonRef}
            content={
              <TooltipContent id={tooltipId} role="tooltip" aria-live="polite">
                <Translation
                  hasTag
                  translation={t('cashback-balance-info-tooltip-content')}
                >
                  Você precisa ter no mínino <br /> R$ 20 para solicitar o
                  resgate
                </Translation>
              </TooltipContent>
            }
            isVisible={isTooltipVisible}
          >
            <HelpButton
              ref={helpButtonRef}
              onClick={handleHelpButtonClick}
              onBlur={handleHelpButtonBlur}
              onKeyDown={handleHelpButtonKeyDown}
              aria-label={t('cashback-balance-info-tooltip-button-label')}
              aria-haspopup="true"
              aria-controls={tooltipId}
              aria-expanded={isTooltipVisible}
            >
              <Icon icon={CircleInfo} />
            </HelpButton>
          </Tooltip>
        </Info>
        {/* // TODO: Adicionar valor do cashback disponível */}
        <Price>
          R$ <strong>0,00</strong>
        </Price>
      </InfoContainer>
      <RedeemButton disabled>
        {t('cashback-balance-redeem-button-text')}
      </RedeemButton>
    </Container>
  );
};
