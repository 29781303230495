import { BehaviorSubject, pipe, tap } from 'rxjs';
import { logOnReduxDevtools } from './debug';

export type CacheHandler<Incoming, Final> = (
  incoming: Incoming,
  cache?: Final
) => Final | undefined;

export function cacheTransformPipe<Incoming, Final>(
  id: string,
  cache$: BehaviorSubject<Final | undefined>,
  cacheHandler?: CacheHandler<Incoming, Final>
) {
  function applyCacheTransform(action: Incoming) {
    const transformedCache = cacheHandler?.(action, cache$.value) as Final;

    if (JSON.stringify(transformedCache) !== JSON.stringify(cache$.value)) {
      setTimeout(() => {
        logOnReduxDevtools(
          { type: `${id}/CACHE/UPDATE`, payload: action },
          { [id]: cache$.value }
        );
      }, 500);
    }

    cache$.next(transformedCache || (action as unknown as Final));
  }

  return pipe(tap(applyCacheTransform));
}
