import { pipe, tap } from 'rxjs';

type InterceptorMessage<Incoming, Cache> = {
  incoming: Incoming;
  cache?: Cache;
};

export type Interceptor<Incoming, Cache> = (
  message: InterceptorMessage<Incoming, Cache>
) => void;

/**
 * Creates an RxJS pipe that sequentially runs a list of interceptors with the incoming value and cache.
 *
 * @param interceptors A list of interceptors to run.
 * @param cache The cache to pass to the interceptors.
 * @returns A rxjs custom pipe.
 */
export const interceptorsPipe = <Incoming, Cache>({
  cache,
  interceptors,
}: {
  interceptors: Interceptor<Incoming, Cache>[];
  cache?: Cache;
}) =>
  pipe(
    tap((incoming: Incoming) => {
      interceptors.forEach((interceptor) => {
        interceptor({
          cache,
          incoming,
        });
      });
    })
  );
