import { generateIndexArray } from '@/presentation/services/utils';
import { CashbackItemSkeleton } from '../CashbackItem/skeleton';

import { Container, List, TitleSkeleton } from './style';

const SKELETON_ITEMS = 6;

export const CashbackHistorySkeleton = () => (
  <Container data-testid="cashback-history-skeleton">
    <TitleSkeleton animate />
    <List>
      {generateIndexArray(SKELETON_ITEMS).map((index) => (
        <li key={index}>
          <CashbackItemSkeleton />
        </li>
      ))}
    </List>
  </Container>
);
