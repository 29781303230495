import { createAuthHeaderWithLocalToken } from '../../auth/tokenManagement';
import {
  getClientOperationMetadata,
  CLIENT_INFO_HEADER_NAME,
  CLIENT_TRACE_HEADER_NAME,
  getClientOperationTraceId,
  getOrCreateUnloggedId,
  UNLOGGED_ID_HEADER_NAME,
} from './tracker';

type GenericObject = Record<string, unknown>;

export const enum GraphQlMethods {
  Post = 'post',
  Get = 'get',
}

export type GqlFetchParams<Variables> = {
  id: string;
  query: string;
  method: GraphQlMethods;
  variables?: Variables;
  metadata?: Record<string, unknown>;
  baseUrl: string;
  extraHeader?: { [key: string]: string };
  isServerRequest?: boolean;
};

export function getFileKeyFromObject(object?: GenericObject) {
  if (typeof File === 'undefined') return '';

  return (
    Object.entries(object || {}).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => value instanceof File
    )?.[0] || ''
  );
}

export const removeQueryWhiteSpaces = (query: string) => {
  const transformedQuery = query
    .replace(/#.*\n/g, '')
    .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
    .replace(/:\s/g, ':')
    .replace(/,\s/g, ',')
    .replace(/\)\s\{/g, '){')
    .replace(/\}\s/g, '}')
    .replace(/\{\s/g, '{')
    .replace(/\s\}/g, '}')
    .replace(/\s\{/g, '{')
    .replace(/\)\s/g, ')')
    .replace(/\(\s/g, '(')
    .replace(/\s\)/g, ')')
    .replace(/\s\(/g, '(')
    .replace(/=\s/g, '=')
    .replace(/\s=/g, '=')
    .replace(/@\s/g, '@')
    .replace(/\s@/g, '@')
    .replace(/\s\$/g, '$')
    .replace(/\s\./g, '.')
    .trim();
  return transformedQuery;
};

type ParseUploadBodyRequest<Variables> = {
  variables?: Variables;
  query: string;
};

export function parseUploadBodyRequest<Variables>({
  variables,
  query,
}: ParseUploadBodyRequest<Variables>): {
  body?: GenericObject;
  formData?: FormData;
} {
  const variableWithInput = (variables as GenericObject)
    ?.input as GenericObject;
  const fileKey = getFileKeyFromObject(variableWithInput);
  const hasImage = !!fileKey;

  if (!hasImage) {
    const body: { query: string; variables?: string } = { query };
    if (variables) {
      body.variables = JSON.stringify(variables);
    }

    return { body };
  }

  const formData = new FormData();
  const mutation = JSON.stringify({ query, variables });
  const map = {
    '0': [`variables.input.${fileKey}`],
  };

  formData.append('operations', mutation);
  formData.append('map', JSON.stringify(map));
  formData.append('0', variableWithInput?.[fileKey] as File);

  return { formData };
}

/**
 *
 * @param baseUrl - The base url to be used for the request
 * @param method - The http method to be used. Accepts GET and POST
 * @param query - The graphql query
 * @param variables - The graphql variables associated with the requests
 * @param metadata - This is used to send extra information to the server. Any key,value pair can be sent and will be included in the request headers as x-sosho-client-info
 * @returns
 */
export function gqlFetch<Variables, Response>({
  baseUrl,
  method,
  query,
  variables,
  metadata,
  extraHeader,
}: GqlFetchParams<Variables>) {
  const isQuery = method === GraphQlMethods.Get;

  const { body, formData } = parseUploadBodyRequest<Variables>({
    query: removeQueryWhiteSpaces(query),
    variables,
  });

  const bodyAsSearchParam = new URLSearchParams(
    body as Record<string, string>
  ).toString();

  const url = isQuery ? `${baseUrl}?${bodyAsSearchParam}` : baseUrl;

  const isUpload = !!formData;

  const uploadHeaders: {
    'content-type'?: string;
  } = !isUpload
    ? {
        'content-type': 'application/json',
      }
    : {};

  const metadataPayload = getClientOperationMetadata(metadata);
  const metadataHeaders = { [CLIENT_INFO_HEADER_NAME]: metadataPayload || '' };
  const traceHeaders = {
    [CLIENT_TRACE_HEADER_NAME]: getClientOperationTraceId() || '',
    [UNLOGGED_ID_HEADER_NAME]: getOrCreateUnloggedId() || '',
  };

  const headers = {
    ...createAuthHeaderWithLocalToken(),
    ...uploadHeaders,
    ...traceHeaders,
    ...metadataHeaders,
    ...extraHeader,
  };

  return fetch(url, {
    method,
    credentials: 'include',
    headers,
    body: isQuery ? undefined : (formData as FormData) || JSON.stringify(body),
  }).then(
    (res) => res.json() as Promise<{ data?: Response; errors?: Error[] }>
  );
}
