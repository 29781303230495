import { CaptionLight, MediaQuery, Theme } from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  align-items: flex-start;

  margin-top: 16px;
  padding: 12px;
  border-radius: 12px;
  min-height: 48px;
  width: 100%;
  background-color: rgba(${Theme.colors.India});
  gap: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    align-items: center;
  }
`;

export const TipText = styled.span`
  ${CaptionLight}
  color: rgba(${Theme.colors.Victor})
`;
