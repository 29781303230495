import { ReactNode, useCallback } from 'react';
import {
  MediaQuery,
  ModalOptions,
  useMediaQuery,
  useModal,
  useTranslation,
} from '@pelando/components';
import BANNER_DESKTOP from 'public/assets/pt-br/login/login-banner.png';
import BANNER_MOBILE from 'public/assets/pt-br/login/login-banner-mobile.png';
import BANNER_DESKTOP_NINJA from 'public/assets/en-us/login/login-banner.png';
import BANNER_MOBILE_NINJA from 'public/assets/en-us/login/login-banner-mobile.png';
import { useRouter } from 'next/router';
import { authModalStyle, BannerImage } from './styles';

export const useAuthModal = () => {
  const { showModal, closeModal, updateOptions } = useModal();
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);
  const { t } = useTranslation('login');
  const { locale } = useRouter();

  const BannerDesktop =
    locale === 'en-US' ? BANNER_DESKTOP_NINJA : BANNER_DESKTOP;

  const BannerMobile = locale === 'en-US' ? BANNER_MOBILE_NINJA : BANNER_MOBILE;

  const showAuthModal = useCallback(
    (content: ReactNode, options?: Partial<ModalOptions> | undefined) =>
      showModal(content, {
        ...options,
        style: authModalStyle,
        bannerImage: options?.bannerImage || (
          <BannerImage
            src={isMobile ? BannerMobile : BannerDesktop}
            alt={t('default-banner-alt-text')}
          />
        ),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile, showModal]
  );

  return {
    showModal: showAuthModal,
    closeModal,
    updateOptions,
  };
};
