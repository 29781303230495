import type { FlagsTypes } from '@/infra/unleash';

export default {
  AUTOMATICALLY_FOLLOW_OFFER: false,
  DISABLE_AD_PLACEMENT: false,
  FEED_ACTIVE_RECOMBEE: false,
  FEED_ACTIVE_RECOMBEE_LOGGEDOUT: false,
  ENABLE_PRODUCT_INDEXING: true,
  WEB_PRODUCT_FILTER_IMAGE_URL_WORKAROUND: true,
  ENABLE_COMMENT_TRIGGER_VOTE_REACTION: false,
  WEB_SEARCH_FACETS_DYNAMIC_UPDATE: false,
  WEB_CARD_FIX_TOP: false,
  WEB_COMMENT_SUGGESTIONS: false,
  WEB_CLICKOUT_RETURN: false,
  WEB_INCENTIVE_STANDARD_TOAST: false,
  WEB_INCENTIVE_BOTTOM_SHEET_DEALS: false,
  WEB_BANNER_INCENTIVE_APP_HOME_DEALS: false,
  ONBOARDING_USER_TYPE_ACTIVE: false,
  WEB_ONBOARD_DEALS: false,
  WEB_WELCOME_STAGE_ONBOARD_DEALS: false,
  WEB_COOKIES_ACTIVE: false,
  WEB_HEADER_BLACK: false,
  WEB_DEALS_RECOMMENDATION: false,
  WEB_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTIONS: false,
  WEB_ONBOARDING_CONTEXT_ALERT_REGISTER: false,
  WEB_COMMUNITY: false,
  WEB_ENABLE_SEARCH_TERMS_SUGGESTIONS: false,
  WEB_ENABLE_NEW_FILTERS_N2: false,
  WEB_ENABLE_BLOG_PAGE: false,
  DEALS_COMPARATOR_PRICE: false,
  WEB_DEALS_FEED_INFINITE: false,

  // INCENTIVE LOGIN
  WEB_INCENTIVE_LOGIN_BANNER_HOME: false,
  WEB_INCENTIVE_LOGIN_BANNER_DEALS: false,
  WEB_INCENTIVE_LOGIN_BANNER_SIDE: false,

  WEB_SEARCH_RESULTS_PRODUCT_BUTTON_CTA: 'Conferir preços',
  AB_TEST_CONTROL: 'unsetted',

  WEB_USERBACK_BUTTON_DISABLED: false,
  WEB_ENABLE_INCENTIVE_ALERT_CREATE: false,

  WEB_ENABLE_BF_LANDING_PAGE: false,
  WEB_ENABLE_NEW_FEED_PAGINATION: false,
  WEB_ENABLE_NEW_PRODUCT_CARD_ON_SEARCH_AB_TEST: 'current',
  WEB_TAB_DEALS: false,
  WEB_ENABLE_BF_INTERNAL_LINKS: false,

  WEB_SEARCH_LABELS_AB_TEST: 'current',
  WEB_PRODUCT_DEALS_LIMIT: '3',
  WEB_COMMENTS_LIMIT_ON_PRODUCT_PAGE: '10',
  WEB_INCENTIVE_FIRST_COMMENT: false,
  WEB_FEED_TAB_RECOMMENDED: false,

  WEB_DISABLE_COMMUNITIES: false,
  WEB_DOPELANDO_TIME: '500',

  WEB_NEW_ALERT_PAGE: false,
  WEB_ENABLE_COMMENT_REPLY_ON_PRODUCT_PAGE: false,
  WEB_N1_DEALS_LIMIT: '10',
  WEB_ENABLE_PRODUCT_FEATURES: false,
  WEB_ENABLE_SEASONAL_FEED: false,
  WEB_ENABLE_BLACK_FRIDAY_CAMPAIGN: false,
  WEB_ENABLE_CASHBACK_CLAIM: false,
  WEB_ENABLE_COUPONS_SEO_PAGE: false,
  WEB_DISABLE_ONBOARDING_VIDEO_STEP: false,
} as FlagsTypes;
