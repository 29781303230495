import Cookies from 'js-cookie';

export const LOCALSTORAGE_TOKEN_KEY = 'AUTH_TOKEN';
export const COOKIE_PROBABLY_LOGGED = 'probably_logged';
export const COOKIE_USER_ID = 'user_id';

const shouldUseLocalAuthToken = () =>
  process.env.NEXT_PUBLIC_USE_DEV_API_LOGIN === 'true' &&
  typeof window !== 'undefined';

export function setAuthTokenToLocalStorage(token: string) {
  if (!shouldUseLocalAuthToken()) return;

  window.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
}

export function removeAuthTokenFromLocalStorage() {
  if (!shouldUseLocalAuthToken()) return;

  window.localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
}

export function getAuthTokenFromLocalStorage() {
  if (!shouldUseLocalAuthToken()) return undefined;

  return window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY) || undefined;
}

export function createAuthHeaderWithLocalToken(): { Authorization?: string } {
  const shouldCreate = process.env.NEXT_PUBLIC_USE_DEV_API_LOGIN === 'true';
  const token = getAuthTokenFromLocalStorage();
  if (!shouldCreate || !token) return {};

  return {
    Authorization: `Bearer ${token}`,
  };
}

export function setUserIsProbablyLoggedCookie(logged: boolean) {
  Cookies.set(COOKIE_PROBABLY_LOGGED, logged.toString());
}

export function getProbablyLoggedCookie() {
  const cookie = Cookies.get(COOKIE_PROBABLY_LOGGED);
  return cookie === 'true';
}

export function removeProbablyLoggedCookie() {
  Cookies.remove(COOKIE_PROBABLY_LOGGED);
}

export function saveUserId(userID: string) {
  window.sessionStorage.setItem(COOKIE_USER_ID, userID);
}

export function getSavedUserId() {
  return window.sessionStorage.getItem(COOKIE_USER_ID);
}

export function removeSavedUserId() {
  return window.sessionStorage.removeItem(COOKIE_USER_ID);
}
