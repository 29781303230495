import {
  SEARCH_TYPE_TRANSLATIONS,
  SearchType,
} from '@/presentation/pages/public/Search/types';
import { Langs } from '@/locales';
import transformToSlug from '../strings/transformToSlug';
import { SEARCH } from './consts';

type SearchURLProps = {
  keyword: string;
  searchType?: SearchType;
  options?: GetSearchUrlOptions;
  locale?: Langs;
};

export const enum SearchPageQueryParams {
  /** @deprecated */
  q = 'q',
  /** @deprecated */
  type = 'type',

  ManualSearch = 'is_manual_search',
  SuggestedSearch = 'suggested_searches',
  // TODO: Centralizar o query param do Dopelando para evitar duplicação
  CameFromDpl = 'came_from_dpl',
  Redirect = 'redirect',
}

export type SearchPageContextQueryParams = Partial<
  Record<SearchPageQueryParams, string>
>;

export type GetSearchUrlOptions = Partial<
  Record<SearchPageQueryParams, boolean>
>;

export const getSearchUrl = ({
  keyword,
  searchType = SearchType.ALL,
  options,
  locale = 'pt-BR',
}: SearchURLProps) => {
  const params = new URLSearchParams();
  const term = transformToSlug(keyword);
  let type = '';

  if (options?.is_manual_search) {
    params.append(SearchPageQueryParams.ManualSearch, 'true');
  }

  if (options?.suggested_searches) {
    params.append(SearchPageQueryParams.SuggestedSearch, 'true');
  }

  if (options?.came_from_dpl) {
    params.append(SearchPageQueryParams.CameFromDpl, 'true');
  }

  if (options?.redirect) {
    params.append(SearchPageQueryParams.Redirect, 'true');
  }

  if (searchType !== SearchType.ALL) {
    type = `/${SEARCH_TYPE_TRANSLATIONS[locale][searchType]}`;
  }

  if (params.toString()) {
    return `${SEARCH[locale]}/${term}${type}?${params.toString()}`;
  }

  return `${SEARCH[locale]}/${term}${type}`;
};
