import { useCallback } from 'react';
import { useModal } from '@pelando/components';
import { type CashbackUserInfo } from '@/domain/entities/Cashback';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { getCurrencyStringByValue } from '@/presentation/services/strings';
import { getPelandoHelpCenterUrl } from '@/presentation/services/urls';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import { CircleCheck, Clock } from '@pelando/fontawesome/icons';

import {
  modalStyle,
  Container,
  StoreImage,
  Header,
  StoreName,
  Value,
  PurchaseInfo,
  History,
  GoBackButton,
  Footer,
  InfoLink,
  PendingIcon,
  SuccessPurchaseIcon,
  HistoryItem,
} from './style';

type CashbackDetailsModalProps = {
  cashbackInfo: CashbackUserInfo;
  onGoBack: () => void;
};

export const CashbackDetailsModal = ({
  cashbackInfo,
  onGoBack,
}: CashbackDetailsModalProps) => {
  const { t } = useTranslation('cashbackBalance');
  const { store, cashbackValue, cashbackPercentage, totalValue, registeredAt } =
    cashbackInfo;

  const storeImage = store?.image?.url;
  const storeName = store?.name;
  const formattedRegisteredAt = new Date(registeredAt).toLocaleDateString();
  const formattedTotalValue = getCurrencyStringByValue(totalValue);
  const formattedCashbackValue = getCurrencyStringByValue(cashbackValue);

  return (
    <Container>
      <Header>
        <StoreImage src={storeImage} alt={storeName} />
        <StoreName>{storeName}</StoreName>
        <Value>{formattedCashbackValue}</Value>
        <PurchaseInfo>
          {cashbackPercentage}% {t('cashback-details-percentage-preposition')}{' '}
          {formattedTotalValue}
        </PurchaseInfo>
      </Header>
      <History>
        <HistoryItem>
          <PendingIcon icon={Clock} />
          <strong>{t('cashback-item-pending-text')}</strong>
          <span>{t('cashback-item-pedning-description')}</span>
          <span>{formattedRegisteredAt}</span>
        </HistoryItem>
        <HistoryItem>
          <SuccessPurchaseIcon icon={CircleCheck} />
          <strong>{t('cashback-item-purchase-completed-text')}</strong>
          <span>{formattedRegisteredAt}</span>
        </HistoryItem>
      </History>
      <Footer>
        <GoBackButton onClick={onGoBack}>
          {t('cashback-details-modal-go-back-button-label')}
        </GoBackButton>
        <InfoLink
          href={getPelandoHelpCenterUrl()}
          rel="noreferrer"
          target="_blank"
        >
          {t('cashback-details-modal-help-center-link-label')}
        </InfoLink>
      </Footer>
    </Container>
  );
};

export const useCashbackDetailsModal = () => {
  const { showModal, closeModal: closeDetailsModal } = useModal();

  const showDetailsModal = useCallback(
    (cashbackInfo: CashbackUserInfo) => {
      showModal(
        <>
          <CashbackDetailsModal
            cashbackInfo={cashbackInfo}
            onGoBack={closeDetailsModal}
          />
          <LockScrollGlobally lock />
        </>,
        {
          style: modalStyle,
        }
      );
    },
    [closeDetailsModal, showModal]
  );

  return {
    showDetailsModal,
    closeDetailsModal,
  };
};
