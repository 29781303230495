import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { Router, useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import {
  RouterAbstractionProvider,
  ToastProvider,
  TranslationProvider,
} from '@pelando/components/contexts';
import { Theme } from '@pelando/components/styles';
import { ModalProvider } from '@pelando/components/components/Modal/context';
import RouteGuard from '@/presentation/providers/routeGuard';
import NextLink from '@/presentation/components/NextLink';
import { NextPageWithLayout } from '@/customNextTypes';
import GlobalStyle from '../presentation/styles/global';
import EventProvider from '../presentation/providers/eventProvider';
import 'quill/dist/quill.snow.css'; // Add the default theme
import { useRouteChange } from '../presentation/hooks/useRouteChange';
import LoadingRoute from '../presentation/providers/loadingRoute';
import { setLandingUrl } from '../infra/api/core/tracker';
import env, { freezeConfigObject } from '../config/environment';
import {
  getAllFlagsOnClientSide,
  handlePageView,
} from '../infra/analytics/sendEventToTrackerProxy';

const DefaultLayout = dynamic(() =>
  import('@/presentation/Layout/default').then((mod) => mod.default)
);

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function registerSW() {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((reg) => {
      // eslint-disable-next-line no-console
      console.log(`Registration succeeded. Scope is ${reg.scope}`);
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log(`Registration failed`);
    });
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const nextHistory = useRouter();
  const { loadingDeal, loadingCoupons, loadingCashbackBalance } =
    useRouteChange(Router.events);
  const getLayout =
    Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);

  useEffect(() => {
    nextHistory.prefetch = async () => {};
  }, [nextHistory]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      freezeConfigObject();
      setLandingUrl();
      const flags = getAllFlagsOnClientSide();
      handlePageView(window.location.pathname, { flags });
    }
  }, []);

  useEffect(() => {
    if (!navigator.serviceWorker || !env.IS_WEBPUSH_ENABLED) {
      return;
    }

    if (document.readyState === 'complete') {
      registerSW();
    } else {
      window.addEventListener('load', registerSW);
      // eslint-disable-next-line consistent-return
      return () => window.removeEventListener('load', registerSW);
    }
  }, []);

  return (
    <>
      <TranslationProvider locale={nextHistory.locale || 'pt-BR'}>
        <RouterAbstractionProvider
          linkComponent={NextLink}
          history={nextHistory}
        >
          <ThemeProvider theme={Theme as unknown as DefaultTheme}>
            <EventProvider>
              <ToastProvider>
                <ModalProvider>
                  {getLayout(
                    <RouteGuard>
                      <Head>
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                        />
                      </Head>
                      <LoadingRoute
                        loadingCoupons={loadingCoupons}
                        loadingDeal={loadingDeal}
                        loadingCashbackBalance={loadingCashbackBalance}
                      >
                        <Component {...pageProps} />
                      </LoadingRoute>
                    </RouteGuard>
                  )}
                  <GlobalStyle />
                </ModalProvider>
              </ToastProvider>
            </EventProvider>
          </ThemeProvider>
        </RouterAbstractionProvider>
      </TranslationProvider>
    </>
  );
}

export default MyApp;
