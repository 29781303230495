import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { CashbackBalancePageSkeleton } from '../pages/secure/cashbackBalance/skeleton';

const DealPageSkeleton = dynamic(() =>
  import('../components/DealPageSkeleton').then((mod) => mod.DealPageSkeleton)
);

const CouponsPageSkeleton = dynamic(() =>
  import('../pages/public/coupon/components/couponsPageSkeleton').then(
    (mod) => mod.default
  )
);

type LoadingRouteProps = PropsWithChildren<{
  loadingDeal: boolean;
  loadingCoupons: boolean;
  loadingCashbackBalance: boolean;
}>;

const LoadingRoute = ({
  children,
  loadingDeal,
  loadingCoupons,
  loadingCashbackBalance,
}: LoadingRouteProps) => {
  if (loadingDeal) return <DealPageSkeleton />;

  if (loadingCoupons) return <CouponsPageSkeleton />;

  if (loadingCashbackBalance) return <CashbackBalancePageSkeleton />;

  return <>{children}</>;
};

export default LoadingRoute;
