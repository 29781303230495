import { LoggedUser } from '../../../../domain/entities/User';

export enum AuthenticationStatus {
  PENDING = 'PENDING',
  LOGGED = 'LOGGED',
  UNLOGGED = 'UNLOGGED',
}

export type GetLoggedUserResponse = {
  me: {
    getUserInfo: LoggedUser;
  };
};

export type LoggedUserCache = {
  loading: boolean;
  loggedUser?: LoggedUser;
  status: AuthenticationStatus;
};

export type GetLoggedUserVariables = void;
