import type { EnUSPages } from './en-US';
import type { PtBRPages } from './pt-BR';

export type Pages = PtBRPages & EnUSPages;

export type PagesKeys = keyof Pages;

export type Langs = 'pt-BR' | 'en-US';

export const GLOBAL_DICTIONARIES_ADDRESS = '__PELANDO_DICTS__';

export type ServerDictionaries = Record<Langs, Pages>;

export const getPageDictionary = <Page extends PagesKeys>(
  page: Page
): Pages[Page] => {
  if (typeof window === 'undefined') {
    return globalThis?.dictionaries?.[page] || null;
  }

  return window[GLOBAL_DICTIONARIES_ADDRESS]?.[page] || null;
};

export function getTranslationKey<Page extends PagesKeys>(
  page: Page,
  key: keyof Pages[Page]
): string | null {
  if (typeof window === 'undefined') {
    return (globalThis?.dictionaries?.[page]?.[key] as string) || null;
  }

  return (window[GLOBAL_DICTIONARIES_ADDRESS]?.[page]?.[key] as string) || null;
}

export function interpolateTranslation<Variable = string | number>(
  translation: string,
  ...variables: Variable[]
) {
  const indexRgx = (index: number) => RegExp(`\\{${index}\\}`, 'g');
  return variables.reduce(
    (acc, variable, index) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc.replace(indexRgx(index), variable as any),
    translation
  );
}

export function getTranslatedString<Page extends PagesKeys>(
  page: Page,
  key: keyof Pages[Page],
  ...variables: unknown[]
) {
  const translation = getTranslationKey(page, key) || '';

  return interpolateTranslation(translation, ...variables);
}
