import { map, pipe } from 'rxjs';
import { ResponseResult } from './request';

export type TransformFunction<Incoming, Variables, Final> = (
  incoming: Incoming,
  variables: Variables
) => Final | undefined;

export function transformResponsePipe<Incoming, Variables, Final>(
  mapperFn?: TransformFunction<Incoming, Variables, Final>
) {
  function applyTransform(action: ResponseResult<Incoming, Variables>) {
    if (action.loading) return action as ResponseResult<undefined, Variables>;
    if (!action.data) return action as ResponseResult<undefined, Variables>;

    const newData = mapperFn
      ? mapperFn(action.data, action.variables as Variables)
      : action.data;

    return {
      ...action,
      data: newData,
    } as ResponseResult<Final, Variables>;
  }

  return pipe(
    map((action: ResponseResult<Incoming, Variables>) => applyTransform(action))
  );
}
