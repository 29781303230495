import { Deal } from '../../../../domain/entities/Offer';
import { IndexSource, Product } from '../../../../domain/entities/Product';
import { Store } from '../../../../domain/entities/Store';
import { ResponseResult } from '../../core/request';
import { Facet, Filter } from './shared';

export type TermSuggestion = { suggestion: string };

type PageInfo = {
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  endCursor?: string;
  startCursor?: string;
};

export const PRICE_FIELD = 'currentMinPrice';

export const enum FederatedSearchContext {
  Deal = 'OFFER',
  Product = 'PRODUCT',
  Store = 'STORE',
  Suggestion = 'SUGGESTION',
}

export type FederatedSearchContextPages = Exclude<
  FederatedSearchContext,
  FederatedSearchContext.Suggestion
>;

export enum FederatedSearchSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FederatedSearchSort = {
  by: string;
  order?: FederatedSearchSortOrder;
};

export type FederatedSearchRules = {
  mainContext: FederatedSearchContext;
};

export type MapEdgesByContext<T extends FederatedSearchContext> = {
  OFFER: Deal;
  PRODUCT: Product;
  STORE: Store;
  SUGGESTION: TermSuggestion;
}[T];

export type FederateSearchReturn<
  T extends FederatedSearchContext = FederatedSearchContext
> = {
  [FederatedSearchContext.Store]: {
    context: T;
    searchId: string;
    edges: MapEdgesByContext<T>[];
    pageInfo: PageInfo;
    total: number;
  };
  [FederatedSearchContext.Product]: {
    context: T;
    searchId: string;
    indexSource: IndexSource;
    edges: MapEdgesByContext<T>[];
    pageInfo: PageInfo;
    facets: Facet[];
    total: number;
  };
  [FederatedSearchContext.Deal]: {
    context: T;
    searchId: string;
    edges: MapEdgesByContext<T>[];
    pageInfo: PageInfo;
    facets: Facet[];
    total: number;
  };
  [FederatedSearchContext.Suggestion]: {
    context: T;
    searchId: string;
    edges: MapEdgesByContext<T>[];
    pageInfo: PageInfo;
    facets: Facet[];
    total: number;
  };
}[T];

export type FederatedSearchResponse<
  Context extends FederatedSearchContext = FederatedSearchContext
> = {
  public: {
    federatedSearchV1: {
      searches: Array<FederateSearchReturn<Context>>;
      rules: FederatedSearchRules | null;
    };
  };
};

export type FederatedSearchInput<
  T extends FederatedSearchContext = FederatedSearchContext
> = {
  context: T;
  query: string;
  filters?: Filter[];
  limit?: number;
  after?: string;
  sort?: FederatedSearchSort;
};

export type FederatedSearchVariables = {
  input: FederatedSearchInput<FederatedSearchContext>[];
} & { facetsToIgnoreUpdate?: string[] };

export type FederatedSearchCacheByContext<T extends FederatedSearchContext> = {
  searchId: string;
  loading: boolean;
  currentTerm: string;
  indexSource?: IndexSource;
  paginationLoading: boolean;
  edges: MapEdgesByContext<T>[];
  pageInfo?: PageInfo;
  total: number;
  facets?: Facet[];
  originalEdges: MapEdgesByContext<T>[];
  initialFacets?: Facet[];
};

export type FederatedSearchCache = {
  deals: FederatedSearchCacheByContext<FederatedSearchContext.Deal>;
  products: FederatedSearchCacheByContext<FederatedSearchContext.Product>;
  stores: FederatedSearchCacheByContext<FederatedSearchContext.Store>;
  loading?: boolean;
  currentTerm?: string;
  mainContext?: FederatedSearchContext;
};

export type CacheTransformResponseParam = ResponseResult<
  FederatedSearchResponse['public']['federatedSearchV1'],
  FederatedSearchVariables
>;

export type CacheTransformFederatedSearchParams<
  Context extends FederatedSearchContext
> = {
  data: FederateSearchReturn<Context>;
  input: FederatedSearchInput<Context>;
  current?: FederatedSearchCacheByContext<Context>;
  facetsToIgnoreUpdate?: string[];
  loading: boolean;
};
