import { styled } from 'styled-components';
import { BodyHeavy, H2Heavy, MediaQuery, Theme } from '@pelando/components';

export const TipSection = styled.section`
  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 28px;
  }
`;

export const TipSectionTitle = styled.h2`
  ${BodyHeavy}
  color: rgba(${Theme.colors.Victor});

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
  }
`;
