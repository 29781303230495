import { FederatedSearchContext } from '../../types/FederatedSearch';

const KEY = 'algoliaQueryIds';

export function saveQueryId(context: FederatedSearchContext, queryID: string) {
  if (typeof window !== 'undefined') {
    const current = JSON.parse(sessionStorage.getItem(KEY) || '{}') || {};

    sessionStorage.setItem(
      KEY,
      JSON.stringify({ ...current, [context]: queryID })
    );
  } else {
    const current = globalThis.algoliaQueryIds || {};
    globalThis.algoliaQueryIds = { ...current, [context]: queryID };
  }
}

export function getQueryId(context: FederatedSearchContext) {
  return typeof window !== 'undefined'
    ? JSON.parse(sessionStorage.getItem(KEY) || '{}')?.[context]
    : globalThis.algoliaQueryIds?.[context];
}
