import { useRouter } from 'next/router';
import { Icon, useModal } from '@pelando/components';
import { ChevronLeft, CircleQuestion } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import Translation from '@/presentation/components/Translation';

import { CashbackTip } from '@/domain/entities/Cashback';
import {
  ActionBar,
  ActionBarButton,
  ActionBarLink,
  Background,
  CashbackIcon,
  Content,
  HeaderBalance,
  Title,
  TitleWrapper,
} from './style';
import { CashbackTipsModal } from '../CashbackTipsModal';

type HeaderProps = {
  tips: CashbackTip[];
};

export const Header = ({ tips }: HeaderProps) => {
  const { t } = useTranslation('cashbackBalance');
  const { back } = useRouter();
  const { showModal } = useModal();

  const handleClickHelCenter = () =>
    showModal(<CashbackTipsModal tips={tips} />);

  return (
    <Background>
      <Content>
        <ActionBar>
          <ActionBarButton
            aria-label={t('header-back-button-label')}
            onClick={back}
          >
            <Icon icon={ChevronLeft} />
          </ActionBarButton>
          <ActionBarLink
            aria-label={t('help-center-link-label')}
            onClick={handleClickHelCenter}
          >
            <Icon icon={CircleQuestion} />
          </ActionBarLink>
        </ActionBar>
        <TitleWrapper>
          <CashbackIcon />
          <Title>
            <Translation hasTag translation={t('header-title')}>
              Meu <span>cashback</span>
            </Translation>
          </Title>
        </TitleWrapper>
      </Content>
      <HeaderBalance />
    </Background>
  );
};
