import { ReactElement, ReactNode } from 'react';
import {
  OfferSortOption,
  ProductSortOptions,
} from '../../../../domain/entities/Filter';
import { FilterValue } from '../../../../infra/api/search/types/shared';

// TODO: mover os tipos de resultados da busca para o domain
export enum SearchType {
  ALL = 'ALL',
  PRODUCTS = 'PRODUCTS',
  DEALS = 'DEALS',
  STORES = 'STORES',
}

export const SEARCH_TYPE_TRANSLATIONS = {
  'en-US': {
    [SearchType.ALL]: 'all',
    [SearchType.PRODUCTS]: 'products',
    [SearchType.DEALS]: 'deals',
    [SearchType.STORES]: 'stores',
  },
  'pt-BR': {
    [SearchType.ALL]: 'tudo',
    [SearchType.PRODUCTS]: 'comparador',
    [SearchType.DEALS]: 'promocoes',
    [SearchType.STORES]: 'lojas',
  },
};

export type SearchTypeFilterProps = {
  onTypeChange: (type: SearchType) => void;
  currentType: SearchType;
};

export type SortByFilterProps<OptionType> = {
  currentSortOption: OptionType;
  options: Array<{
    title: string | ReactElement;
    value: OptionType;
    icon: ReactElement;
  }>;
  buttonTitle: ReactNode;
  onSortOptionChange: (order: OptionType) => void;
};

export type FilterProps = {
  filters: FilterMap;
  onMultipleTextFieldChange: (
    field: string,
    values: FilterValue['text']
  ) => void;
  onRangeFieldChange: (
    field: string,
    values: Omit<FilterValue, 'text'>
  ) => void;
  onClearFilters: () => void;
  onApplyFilters: (filters: FilterMap) => void;
};

export type ProductsFiltersProps = FilterProps & {
  onToggleProductsHotFilter: () => void;
  isProductsHotFilterActive?: boolean;
};

export type FilterMap = Map<string, FilterValue>;

export type FilterByType = Record<SearchType, FilterMap>;

export type SortByType = {
  [SearchType.ALL]: undefined;
  [SearchType.STORES]: undefined;
  [SearchType.PRODUCTS]: ProductSortOptions;
  [SearchType.DEALS]: OfferSortOption;
};

export type SearchContextType = {
  searchTerm: string;

  searchType: SearchType;
  changeSearchType: (type: SearchType) => void;

  filters: FilterByType;
  addFilter: (type: SearchType, field: string, value: FilterValue) => void;
  removeFilter: (type: SearchType, field: string) => void;
  updateFilters: (type: SearchType, filters: FilterMap) => void;
  clearFilters: (type?: SearchType) => void;

  sortByType: SortByType;
  updateSort: <Type extends SearchType>(
    type: Type,
    sort: SortByType[Type]
  ) => void;
};
