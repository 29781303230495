import {
  Container,
  IconSkeleton,
  Info,
  InfoSkeleton,
  InfoSkeletonWrapper,
  StoreSkeleton,
  ValueSkeleton,
} from './style';

export const CashbackItemSkeleton = () => (
  <Container>
    <Info>
      <IconSkeleton animate />
      <InfoSkeletonWrapper>
        <StoreSkeleton animate />
        <InfoSkeleton animate />
        <InfoSkeleton animate />
      </InfoSkeletonWrapper>
    </Info>
    <ValueSkeleton animate />
  </Container>
);
