import { CashbackTip } from '@/domain/entities/Cashback';
import { useUserCashbacks } from '../../hooks/useUserCashbacks';
import { CashbackTips } from '../CashbackTips';
import { CashbackHistory } from '../CashbackHistory';
import { CashbackHistorySkeleton } from '../CashbackHistory/skeleton';

type EnhancedUserCashbacksProps = {
  tips: CashbackTip[];
};

export const EnhancedUserCashbacks = ({ tips }: EnhancedUserCashbacksProps) => {
  const { cashbacks, loading } = useUserCashbacks();

  const hasCashbacks = !!cashbacks?.length;

  if (loading) {
    return <CashbackHistorySkeleton />;
  }

  if (!hasCashbacks) {
    return <CashbackTips tips={tips} />;
  }

  return <CashbackHistory items={cashbacks} />;
};
