import { BehaviorSubject, map, Observable } from 'rxjs';
import { createRequest, CreateRequestParams, ResponseResult } from './request';
import { CacheHandler, cacheTransformPipe } from './cacheTransformPipe';
import {
  TransformFunction,
  transformResponsePipe,
} from './transformResponsePipe';
import { toPromise } from './helpers/toPromise';
import { GuardFunction } from './guards';
import { guardsPipe } from './guards/guardsPipe';
import { ClientInfoMetadata } from './tracker';
import { Interceptor, interceptorsPipe } from './interceptors';

type EndpointOptions = {
  extraHeaders?: { [key: string]: string };
  isServerRequest?: boolean;
};

type EndpointParams<Response, Variables, Transformed, Cache, Metadata> =
  CreateRequestParams & {
    id: string;
    transform?: TransformFunction<Response, Variables, Transformed>;
    cacheTransform?: CacheHandler<
      ResponseResult<Transformed, Variables>,
      Cache
    >;
    guards?: GuardFunction<Response, Variables>[];
    metadata?: Metadata;
    interceptors?: Interceptor<ResponseResult<Transformed, Variables>, Cache>[];
  };

export const createNewEndpoint = <
  Response,
  Variables,
  Transformed = Response,
  Cache = ResponseResult<Transformed, Variables>,
  Metadata extends ClientInfoMetadata = ClientInfoMetadata
>({
  id,
  query,
  method,
  guards = [],
  cacheTransform,
  transform,
  interceptors = [],
}: EndpointParams<Response, Variables, Transformed, Cache, Metadata>) => {
  const cache$ = new BehaviorSubject<Cache | undefined>(undefined);

  const baseRequest = createRequest<Response, Variables>({
    id,
    query,
    method,
  });

  // TODO: [sc-18711] change to use an object instead of positional arguments
  // TODO: [sc-20056] fix retry callback on guardspipe
  const request = (
    variables: Variables,
    metadata?: Metadata | undefined,
    options?: EndpointOptions
  ): Observable<ResponseResult<Transformed, Variables>> =>
    baseRequest(
      variables,
      metadata,
      options?.extraHeaders,
      options?.isServerRequest
    ).pipe(
      guardsPipe(id, guards, () =>
        setTimeout(() => toPromise(request(variables)), 0)
      ),
      transformResponsePipe(transform),
      interceptorsPipe({
        interceptors,
        cache: cache$.value,
      }),
      cacheTransformPipe(id, cache$, cacheTransform),
      map((action) => {
        if (action.error) {
          throw action;
        }
        return action;
      })
    );

  // TODO: [sc-18711] change to use an object instead of positional arguments
  const requestAsPromise = (
    variables: Variables,
    metadata?: Metadata,
    options?: EndpointOptions
  ): Promise<ResponseResult<Transformed, Variables>> =>
    toPromise(request(variables, metadata, options));

  return { request, cache$, requestAsPromise };
};
