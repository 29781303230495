import env from '../../../config/environment';

let DEVTOOLS: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send: (name: any, state: any) => any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let devtoolsState: any = {};

export function getDevTools() {
  if (typeof window === 'undefined') return undefined;
  if (!DEVTOOLS)
    DEVTOOLS = window.__REDUX_DEVTOOLS_EXTENSION__?.connect({
      name: 'New State Management',
    });
  return DEVTOOLS;
}

export function logOnReduxDevtools(
  { type, ...rest }: { type: string } & Record<string, unknown>,
  state?: unknown
) {
  if (
    !env.PUBLIC_DEBUG_API_STATE_MANAGEMENT ||
    env.PUBLIC_DEBUG_API_STATE_MANAGEMENT !== 'true'
  ) {
    return;
  }
  const devtools = getDevTools();
  devtoolsState = Object.assign(devtoolsState, state);
  devtools?.send({ type, ...(rest || {}) }, devtoolsState);
}
