export enum AuthKind {
  LOGIN = 'login',
  LOGIN_NO_PASSWORD = 'login-no-password',
  SIGNUP = 'signup',
  RESET_PASS = 'reset-password',
  UPDATE_PASS = 'update-password',
  LOGIN_BETA = 'login-beta',
}

export type AuthModalState = {
  authKind: AuthKind;
  email?: string;
  providers?: AuthProvider[];
};

export type AuthModalHandler = (authState: AuthModalState) => void;

export enum AuthProvider {
  LOCAL = 'local',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export type SocialProvidersType =
  | AuthProvider.FACEBOOK
  | AuthProvider.GOOGLE
  | AuthProvider.APPLE;

export enum AuthProviderCodeResponse {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK_LOGIN_RESPONSE',
  GOOGLE = 'GOOGLE_LOGIN_RESPONSE',
  APPLE = 'APPLE_LOGIN_RESPONSE',
}

export type SocialUserData = {
  email: string;
  name: string;
  image: string;
};

export type FacebookLoginData = {
  user: SocialUserData;
  requestEmailUpdate: boolean;
};

export type GoogleLoginData = {
  user: SocialUserData;
};

export type AppleLoginData = {
  user: SocialUserData;
};

export enum AuthErrorExtensionCode {
  CONFLICT = 'CONFLICT',
}

export type ResetPasswordToken = string;

export const enum LoginComponentSourceName {
  authModal = 'auth_modal',
  homeAuthBanner = 'home_auth_banner',
  sideBarAuthBanner = 'side_bar_auth_banner',
  dealCommentsAuthBanner = 'deal_comments_auth_banner',
  googleOneTap = 'google_one_tap', // Also set on the backend
  modalComment = 'modal_comment',
  modalVote = 'modal_vote',
  modalAlert = 'modal_alert',
  modalFollow = 'modal_follow',
  modalSave = 'modal_save',
  authRoute = 'modal_auth_route',
  headerMenuItem = 'header_menu',
  cashbackAuthGuard = 'cashback_auth_guard',
}
