import { CashbackHistorySkeleton } from './components/CashbackHistory/skeleton';
import { HeaderSkeleton } from './components/Header/skeleton';
import { BalanceSectionSkeleton } from './components/BalanceSection/skeleton';
import { Container, Content } from '.';

export const CashbackBalancePageSkeleton = () => (
  <Container>
    <HeaderSkeleton />
    <Content>
      <BalanceSectionSkeleton />
      <CashbackHistorySkeleton />
    </Content>
  </Container>
);
