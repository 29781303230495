export const ALL_STORES_URLS = {
  'pt-BR': '/cupons-de-descontos',
  'en-US': '/promo-codes',
};

export const LOGIN = {
  'pt-BR': '/entrar',
  'en-US': '/login',
};

export const SIGN_UP = {
  'pt-BR': '/cadastrar',
  'en-US': '/signup',
};

export const ALERTS = {
  'pt-BR': '/meus-alertas',
  'en-US': '/my-alerts',
};

export const PROMOS = {
  'pt-BR': '/promocoes-salvas',
  'en-US': '/saved-promos',
};

export const SETTINGS = {
  'pt-BR': '/configuracoes',
  'en-US': '/settings',
};

export const CONTACT = {
  'pt-BR': '/atendimento',
  'en-US': '/service',
};

export const CATEGORIES = {
  'pt-BR': '#categorias',
  'en-US': '#categories',
};

export const COMFIRM_EMAIL = {
  'pt-BR': '/confirmar-email',
  'en-US': '/confirm-email',
};

export const COMPARATOR = {
  'pt-BR': '/comparador',
  'en-US': '/comparator',
};

export const POST = {
  'pt-BR': '/postar',
  'en-US': '/post',
};

export const EDIT_OFFER = {
  'pt-BR': '/editar-oferta',
  'en-US': '/edit-offer',
};

export const ABOUT = {
  'pt-BR': '/sobre',
  'en-US': '/about',
};

export const PRIVACY_POLICY = {
  'pt-BR': '/politica-de-privacidade',
  'en-US': '/privacy-policy',
};

export const COOKIES_POLICY = {
  'pt-BR': '/politica-de-cookies',
  'en-US': '/cookies-policy',
};

export const CONDUCT_CODE = {
  'pt-BR': '/codigo-de-conduta',
  'en-US': '/code-of-conduct',
};

export const TERMS = {
  'pt-BR': '/termos-de-uso',
  'en-US': '/terms-of-use',
};

export const SEARCH = {
  'pt-BR': '/busca',
  'en-US': '/s',
};

export const PROFILE = {
  'pt-BR': '/perfil',
  'en-US': '/profile',
};

export const MY_PROFILE = {
  'pt-BR': '/meu-perfil',
  'en-US': '/my-profile',
};

export const FOLLOWERS = {
  'pt-BR': '/seguidores',
  'en-US': '/followers',
};

export const FOLLOWING = {
  'pt-BR': 'seguindo',
  'en-US': 'following',
};

export const MY_DATA = {
  'pt-BR': '/meus-dados',
  'en-US': '/my-data',
};

export const CHANGE_PASSWORD = {
  'pt-BR': '/alterar-senha',
  'en-US': '/change-password',
};

export const CONFIRM_DELETE_ACCOUNT = {
  'pt-BR': '/confirmar-exclusao-conta',
  'en-US': '/confirm-account-removal',
};

export const HOST = {
  'pt-BR': 'www.pelando.com.br',
  'en-US': 'www.promoninja.com',
};

export const INSTAGRAM = {
  'pt-BR': 'https://www.instagram.com/pelandobr',
  'en-US': 'https://www.instagram.com/promoninja_us',
};

export const TIKTOK = {
  'pt-BR': 'https://www.tiktok.com/@pelandobr',
  'en-US': 'https://www.tiktok.com/@promoninja_us',
};

export const TWITTER = {
  'pt-BR': 'https://x.com/pelandobr',
  'en-US': 'https://x.com/promoninja_us',
};

export const FACEBOOK = {
  'pt-BR': 'https://www.facebook.com/share/T7vQvrkCUa4hZWjd/?mibextid=qi2Omg',
  'en-US': null,
};

export const USERBACK = {
  'pt-BR': 'https://pelando-site.ideas.userback.io/p/9RjeK84h82',
  'en-US': 'https://promoninja.ideas.userback.io/',
};

export const HELP_CENTER = {
  'pt-BR': 'https://ajuda.pelando.com.br',
  'en-US': 'https://help.promoninja.com',
};

export const BLUESKY = {
  'pt-BR': 'https://bsky.app/profile/pelando.com.br',
  'en-US': null,
};

export const CLIENT_EXTERNAL_URL = {
  'pt-BR': 'https://www.promoninja.com/',
  'en-US': 'https://www.pelando.com.br/',
};
