import { memo } from 'react';
import Head from 'next/head';
import {
  getDefaultDescription,
  getDefaultOGImage,
  getDefaultOGType,
  getDefaultTitle,
  getFacebookAppID,
  getRobotsWithDisallowSearchIndexFeatureFlag,
} from '../../services/seo';

export type OGTags = Partial<{
  title: string;
  description: string;
  type: string;
  url: string;
  image: string;
  updatedTime: string;
  facebookAppId: string;
}>;

export type SEOProps = Partial<{
  title: string;
  description: string;
  canonical: string;
  robots: string;
  rss: string;
  og: OGTags;
}>;

const SEO = ({
  title = getDefaultTitle(),
  description = getDefaultDescription(),
  canonical,
  robots,
  og,
  rss,
}: SEOProps) => {
  const finalOG: OGTags = {
    title,
    description,
    url: canonical,
    type: getDefaultOGType(),
    image: getDefaultOGImage(),
    facebookAppId: getFacebookAppID(),
    ...(og || {}),
  };

  return (
    <Head>
      {title && <title key="title">{title}</title>}
      {finalOG.title && (
        <meta key="og:title" property="og:title" content={finalOG.title} />
      )}
      {description && (
        <meta key="description" name="description" content={description} />
      )}
      {finalOG.description && (
        <meta
          key="og:description"
          property="og:description"
          content={finalOG.description}
        />
      )}
      {canonical && <link key="canonical" rel="canonical" href={canonical} />}
      {finalOG.url && (
        <meta key="og:url" property="og:url" content={finalOG.url} />
      )}
      {robots && (
        <meta
          key="robots"
          name="robots"
          content={getRobotsWithDisallowSearchIndexFeatureFlag(robots)}
        />
      )}
      {rss && (
        <link key="rss" rel="alternate" type="application/rss+xml" href={rss} />
      )}
      {finalOG.type && (
        <meta key="og:type" property="og:type" content={finalOG.type} />
      )}
      {finalOG.image && (
        <meta key="og:image" property="og:image" content={finalOG.image} />
      )}
      {finalOG.updatedTime && (
        <meta
          key="og:updated_time"
          property="og:updated_time"
          content={finalOG.updatedTime}
        />
      )}
      {finalOG.facebookAppId && (
        <meta
          key="fb:app_id"
          property="fb:app_id"
          content={finalOG.facebookAppId}
        />
      )}
    </Head>
  );
};

export default memo(SEO) as unknown as React.FC<SEOProps>;
