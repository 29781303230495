import { getTranslatedString } from '@/locales';
import env, { serverConfigKeys } from '../../../config/environment';
import { getAbsoluteClientUrl } from '../urls';

export function getDefaultTitleDescription(type: 'title' | 'description') {
  if (type === 'title') {
    return getTranslatedString('common', 'default-page-title');
  }
  if (type === 'description') {
    return getTranslatedString('common', 'default-page-description');
  }
  return undefined;
}

export const getDefaultTitle = () =>
  getTranslatedString('common', 'seo-default-title') || '';

export const getDefaultDescription = () =>
  getTranslatedString('common', 'seo-default-description') || '';

export const getAllStoresTitle = () =>
  `Cupons de desconto, Códigos Promocionais, Ofertas e Promoções | Pelando`;

export const getFacebookAppID = () => serverConfigKeys.FACEBOOK_APP_ID || '';

export const getDefaultOGType = () => 'website';

export const getDefaultOGImage = () => getAbsoluteClientUrl('/favicon.svg');

export const getRssUrl = (path: string) => getAbsoluteClientUrl(`/rss/${path}`);

export const getRobotsWithDisallowSearchIndexFeatureFlag = (robots: string) =>
  env.ALLOW_SEARCH_INDEX ? robots : 'noindex, nofollow';

const SEO_COUPONS_SLUGS = [
  'carrefour',
  'americanas.com.br',
  'extra',
  'casasbahia.com.br',
  'pontofrio.com.br',
  'pt.aliexpress.com',
  'amazon.com.br',
  'mercadolivre.com.br',
  'shopee.com.br',
  'magazineluiza.com.br',
  'efacil.com.br',
  'fastshop.com.br',
  'kabum.com.br',
  'loja.electrolux.com.br',
  'samsung.com.br',
  'lg.com',
];

export const isSEOCouponSlug = (slug: string) =>
  SEO_COUPONS_SLUGS.includes(slug);

export const isOldLGSlug = (slug: string) =>
  slug === 'lgg5comprouganhou.com.br';

export const getNewLGSlug = () => 'lg.com';
