import { getLoggedUser } from '../../../infra/api/auth/endpoints';
import { AuthenticationStatus } from '../../../infra/api/auth/types/GetLoggedUser';
import { useApiCache } from '../useApiCache';

export function useCurrentUserCache() {
  const result = useApiCache(getLoggedUser.cache$) || {
    loading: false,
    status: AuthenticationStatus.UNLOGGED,
  };

  const isLogged = result.status === AuthenticationStatus.LOGGED;

  return { ...result, isLogged };
}
