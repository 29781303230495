import { pipe, Subject, takeUntil, tap } from 'rxjs';
import { GuardFunction } from '.';
import { ResponseResult } from '../request';
import { logOnReduxDevtools } from '../debug';

/**
 * An RxJs pipe that runs the provided functions (guards) to check if a request can continue.
 * If any of these functions return `true` then the observable is canceled and request flow stop.
 * If all these functions return `false` then the observable is not canceled and the request flow continues.
 *
 * @param id Request ID
 * @param guards Guard functions that will be executed.
 * @param retry Function that will be called when the user try another attempt.
 * @returns A rxjs custom pipe.
 */
export function guardsPipe<Response, Variables>(
  id: string,
  guards: GuardFunction<Response, Variables>[],
  retry: () => void
) {
  const cancel$ = new Subject();

  return pipe(
    tap((action: ResponseResult<Response, Variables>) => {
      const result = guards?.find((guard) =>
        guard({
          ...action,
          id,
          retry,
        })
      );

      if (result) {
        logOnReduxDevtools({ type: `${id}/GUARD/REFUSED`, guard: result.name });
        cancel$.next(true);
      }
    }),
    takeUntil(cancel$)
  );
}
