const prefix = '@auth';

export const AuthActionType = {
  RequestPasswordReset: `${prefix}/REQUEST_PASSWORD_RESET`,
  UpdatePassword: `${prefix}/UPDATE_PASSWORD`,
  RequestAccountDeletion: `${prefix}/REQUEST_ACCOUNT_DELETION`,
  UpdateEmail: `${prefix}/UPDATE_EMAIL`,
  SendEmailConfirmation: `${prefix}/SEND_EMAIL_CONFIRMATION`,
  AgreeWithLastPrivacyTerms: `${prefix}/AGREE_WITH_LAST_PRIVACY_TERMS`,
  GetLoggedUser: `${prefix}/GET_LOGGED_USER`,
  GetAuthProviders: `${prefix}/GET_AUTH_PROVIDERS`,
  Logout: `${prefix}/LOGOUT`,
  Login: `${prefix}/LOGIN`,
  SignUp: `${prefix}/SIGN_UP`,
  UpdateSignUpMetadata: `${prefix}/UPDATE_SIGN_UP_METADATA`,
};
