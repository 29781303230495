import { filter, map } from 'rxjs';
import {
  EventCategory,
  GenericAnalyticsPayload,
} from '@/domain/events/analytics';
import { parseEventDetail } from '../events';
import { getPelandoEvents$ } from '../streams';
import { triggerEvent as handleGaEvents } from './ga';
import { handleAlgoliaEvents } from './algolia';
import { handleTrackerEvents } from './sendEventToTrackerProxy';

// TODO: [sc-18712] Change this file to be a non-dependant module
export default function initAnalyticsSubscribe(
  pelandoEvents$: ReturnType<typeof getPelandoEvents$>
) {
  return pelandoEvents$
    .pipe(
      map((event: Event) => parseEventDetail(event)),
      filter((data) => data.eventName === 'analytics')
    )
    .subscribe((data) => {
      const { name, extra, category, skipAlgoliaEvent } =
        data.payload as GenericAnalyticsPayload;

      if (category !== EventCategory.Algolia) {
        handleTrackerEvents(name, category, extra);
        handleGaEvents({ action: name, category, ...extra });
      }

      if (skipAlgoliaEvent) return;

      handleAlgoliaEvents(data.payload as GenericAnalyticsPayload);
    });
}
