import {
  FederateSearchReturn,
  FederatedSearchCacheByContext,
  FederatedSearchContext,
  FederatedSearchInput,
  FederatedSearchVariables,
} from '../../types/FederatedSearch';

type ObjectWithContext = {
  context: FederatedSearchContext;
};

export const isFederatedSearchProducts = ({ context }: ObjectWithContext) =>
  context === FederatedSearchContext.Product;

export const isFederatedSearchDeal = ({ context }: ObjectWithContext) =>
  context === FederatedSearchContext.Deal;

export const isFederatedSearchStore = ({ context }: ObjectWithContext) =>
  context === FederatedSearchContext.Store;

export const isFederatedSearchSuggestion = ({ context }: ObjectWithContext) =>
  context === FederatedSearchContext.Suggestion;

export const paginateSearch = <
  Context extends FederatedSearchContext = FederatedSearchContext
>({
  input,
  incomming,
  current,
}: {
  current?: Pick<
    FederatedSearchCacheByContext<Context>,
    'currentTerm' | 'edges'
  >;
  incomming?: Pick<FederateSearchReturn<Context>, 'edges'>;
  input?: Pick<FederatedSearchInput<Context>, 'after' | 'query'>;
}): FederateSearchReturn<Context>['edges'] => {
  const currentTerm = current?.currentTerm || '';
  const currentEdges = current?.edges || [];

  const incommingEdges = incomming?.edges || [];
  const incommingTerm = input?.query;

  const sameSearchTerm = incommingTerm === currentTerm;
  const paginated = !!input?.after;

  return !sameSearchTerm || !paginated
    ? incommingEdges
    : ([
        ...currentEdges,
        ...incommingEdges,
      ] as FederateSearchReturn<Context>['edges']);
};

type DataByContext = {
  [key in FederatedSearchContext]: FederateSearchReturn<key>;
};

export const mapDataByContext = (data?: FederateSearchReturn[]) =>
  (data || []).reduce(
    (acc, search) => ({
      ...acc,
      [search.context]: search,
    }),
    {} as DataByContext
  );

type InputByContext = {
  [key in FederatedSearchContext]: FederatedSearchInput<key>;
};

export const mapInputByContext = (inputs?: FederatedSearchVariables['input']) =>
  (inputs || []).reduce(
    (acc, input) => ({
      ...acc,
      [input.context]: input,
    }),
    {} as InputByContext
  );
