import { PAGE_INFO_FRAGMENT } from '../../offers/queries/shared';
import { CASHBACKS_FRAGMENT } from '../fragments';

export const GET_CASHBACKS_QUERY = `
query Cashbacks(
    $limit: Int,
    $after: String
) {
    me {
        cashbacks(
            limit: $limit,
            after: $after
        ) {
            edges {
                ...CashbackUserInfoFragment
            }
            pageInfo {
                ...PageInfoFragment
            }
        }
    }
}
    ${CASHBACKS_FRAGMENT}
    ${PAGE_INFO_FRAGMENT}
`;
