import React, { Children, ReactNode } from 'react';

type TranslationProps = {
  translation: string;
  children: ReactNode;
  hasTag?: boolean;
};

const Translation = ({ translation, children, hasTag }: TranslationProps) => {
  const arrayChildren = Children.toArray(children);
  const arrayTranslation = hasTag
    ? translation.split(/(<tag>.*?<\/tag>)/)
    : translation.split(/<[0-9]>/);
  let indexTranslation = 0;

  const translateChild = (child: React.ReactNode) => {
    if (typeof child === 'string') {
      const translatedText = arrayTranslation[indexTranslation];
      indexTranslation += 1;
      return translatedText;
    } if (typeof child === 'object' && child !== null) {
      return '';
    } 
      return child;
    
  };

  if (hasTag) {
    const translatedChildren = arrayChildren.map(translateChild);

    const htmlString = translatedChildren.join('');

    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }

  arrayChildren.forEach((c, index) => {
    if (typeof c !== 'object') {
      arrayChildren[index] = arrayTranslation[indexTranslation];
    } else {
      indexTranslation += 1;
    }
  });

  return <>{arrayChildren}</>;
};

export default Translation;
