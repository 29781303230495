declare global {
  interface Window {
    __PELANDO_CONFIG__: PublicConfig;
  }
}

/**
 * For server-only code use the server/config module!
 *
 * This module is responsible for handling the configuration access
 * to our runtime "env" variables. Since we use the same code for
 * client and SSR environments (isomorphic), we must have a way to
 * read the correct configuration for each runtime.
 *
 * For SSR, we use the process.env as an usual node.js app.
 *
 * For the client (browser/app) env we rely on a global variable
 * that is populated upon the first request and holds public config
 * values. That global value is placed by the server on every landing
 * request at the `window` object, therefore it is available before
 * any other script execution. The "gotcha" here is that this window
 * variable will be parsed and executed synchronously before any other
 * script to avoid race conditions.
 *
 * For extra security, we freeze that object so no third-party code
 * can change it's content directly.
 */

/**
 * Those are the shared "environment" values. Be aware that if you
 * add something here it will be public available on the resulting
 * HTML.
 *
 * DO NOT ADD SECRETS HERE!!!
 */
export const publicConfigKeys = {
  PUBLIC_VERSION: process.env.PUBLIC_VERSION,
  PUBLIC_API_BASE_URL: process.env.PUBLIC_API_BASE_URL,
  PUBLIC_REST_API_URL: process.env.PUBLIC_REST_API_URL,
  PUBLIC_API_SUBDOMAIN: process.env.PUBLIC_API_SUBDOMAIN,
  PUBLIC_CLIENT_BASE_URL: process.env.PUBLIC_CLIENT_BASE_URL,
  PUBLIC_BACKOFFICE_BASE_URL: process.env.PUBLIC_BACKOFFICE_BASE_URL,
  PUBLIC_EVENT_TRACKING_URL: process.env.PUBLIC_EVENT_TRACKING_URL,
  PUBLIC_GA_KEY: process.env.PUBLIC_GA_KEY,
  PUBLIC_USERBACK_TOKEN: process.env.PUBLIC_USERBACK_TOKEN,
  PUBLIC_BASE_CANONICAL_URL: process.env.PUBLIC_BASE_CANONICAL_URL,
  DEBUG_API_STATE_MANAGEMENT: process.env.DEBUG_API_STATE_MANAGEMENT,
  IS_WEBPUSH_ENABLED: process.env.IS_WEBPUSH_ENABLED === 'true',
  ALLOW_SEARCH_INDEX: process.env.ALLOW_SEARCH_INDEX === 'true',
  PUBLIC_DEBUG_API_STATE_MANAGEMENT:
    process.env.PUBLIC_DEBUG_API_STATE_MANAGEMENT,
  PUBLIC_ALGOLIA_APPID: process.env.PUBLIC_ALGOLIA_APPID,
  PUBLIC_ALGOLIA_API_KEY: process.env.PUBLIC_ALGOLIA_API_KEY,
  PUBLIC_PELANDO_DOMAIN: process.env.PUBLIC_PELANDO_DOMAIN,
  PUBLIC_STRAPI_URL: process.env.PUBLIC_STRAPI_URL as string,
  PUBLIC_STRAPI_TOKEN: process.env.PUBLIC_STRAPI_TOKEN as string,
  AUTH_GOOGLE_APP_ID: process.env.AUTH_GOOGLE_APP_ID,
  FIREBASE_CONFIG: process.env.FIREBASE_CONFIG,
  PUBLIC_SEO_COUPONS_URL: process.env.PUBLIC_SEO_COUPONS_URL,
};

export type PublicConfig = typeof publicConfigKeys;

export type PublicConfigKeys = keyof typeof publicConfigKeys;

export const serverConfigKeys = {
  NODE_ENV: process.env.NODE_ENV,
  IS_WEBPUSH_ENABLED: process.env.IS_WEBPUSH_ENABLED === 'true',
  SITEMAPS_PROXY_TARGET: process.env.SITEMAPS_PROXY_TARGET,
  RSS_PROXY_TARGET: process.env.RSS_PROXY_TARGET,
  FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
  NEW_RELIC_LICENSE_KEY: process.env.NEW_RELIC_LICENSE_KEY,
  NEW_RELIC_APP_NAME: process.env.NEW_RELIC_APP_NAME,
  API_CLUSTER_BASE_URL: process.env.API_CLUSTER_BASE_URL,
};

export const GLOBAL_CONFIG_ADDRESS = '__PELANDO_CONFIG__';

/**
 * Freeze the global config object so no other script can
 * do something nasty like config.api = "evil.example.com"
 */
export const freezeConfigObject = () => {
  Object.freeze(window[GLOBAL_CONFIG_ADDRESS]);
};

const getPublicConfigObject = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window[GLOBAL_CONFIG_ADDRESS] !== 'undefined' &&
    process.env.NODE_ENV !== 'test'
  ) {
    return window[GLOBAL_CONFIG_ADDRESS];
  }

  return publicConfigKeys;
};

const env = getPublicConfigObject();

export default env;
