import Link from 'next/link';
import { AbstractedLinkProps } from '@pelando/components';

function NextLink({ href, children, ...props }: AbstractedLinkProps) {
  return (
    <Link href={String(href)} passHref prefetch={false} legacyBehavior>
      <a {...props}>{children}</a>
    </Link>
  );
}

export default NextLink;
