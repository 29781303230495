import env from '@/config/environment';
import { FlagsTypes } from '@/infra/unleash/types';
import defaultFlags from './defaults';

export { UnleashFlags } from './types';

export { defaultFlags };
export type { FlagsTypes };

export const UNLEASH_SESSION_ID_STRING = 'unleash-session-id';
export const UNLEASH_FLAGS_URL = `${env.PUBLIC_CLIENT_BASE_URL}/flags`;
export const FLAGS_TRANSPORT_HEADER = 'X-Flags';

export const createUnleashSessionCookie = (sessionId: string) =>
  `${UNLEASH_SESSION_ID_STRING}=${sessionId}; path=/; Max-Age=365; Domain=${env.PUBLIC_PELANDO_DOMAIN}; SameSite=Strict; HttpOnly;`;

export const getFlag = <Key extends keyof FlagsTypes>(
  toggle: Key
): FlagsTypes[Key] => {
  try {
    return typeof window !== 'undefined'
      ? window?.flags?.[toggle]
      : globalThis?.flags?.[toggle];
  } catch (error) {
    return defaultFlags?.[toggle];
  }
};
