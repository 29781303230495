import { MediaQuery, ModalHeader } from '@pelando/components';
import Image from 'next/image';
import { css, styled } from 'styled-components';

export const authModalStyle = css`
  padding: 16px 16px 24px;
  height: calc(100% - 40px);

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 16px 24px 24px;
    max-width: 600px;
  }

  > :first-child {
    margin-bottom: 4px;
    @media ${MediaQuery.SCREEN_MD_UP} {
      margin-bottom: 8px;
    }
  }

  ${ModalHeader} {
    display: none;
  }
`;

export const BannerImage = styled(Image)`
  width: 767px;
  height: 186px;
  z-index: -1;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 600px;
    height: 222px;
  }
`;
