export type ResponseResult<Response, Variables> = LoadingResponse<Variables> &
  Partial<SuccessResponse<Response, Variables> & ErrorResponse<Variables>>;

export type SuccessResponse<Data, Variables = unknown> = {
  data: Data;
  variables: Variables;
};

export function successMessage<Response, Variables>(
  data: Response,
  variables: Variables
): ResponseResult<Response, Variables> {
  return {
    data,
    variables,
    error: undefined,
    loading: false,
  };
}

export type ErrorResponse<Variables = unknown> = {
  error: Error;
  variables: Variables;
};

export function errorMessage<Variables>(
  error: Error,
  variables: Variables
): ResponseResult<undefined, Variables> {
  return {
    data: undefined,
    variables,
    error,
    loading: false,
  };
}

export type LoadingResponse<Variables = unknown> = {
  loading: boolean;
  variables?: Variables;
};

export function loadingMessage<Variables>(
  variables: Variables
): ResponseResult<undefined, Variables> {
  return {
    data: undefined,
    variables,
    error: undefined,
    loading: true,
  };
}
