import SEO from '@/presentation/components/SEO';
import { getCanonicalUrl } from '@/presentation/services/urls';
import { getCashbackBalanceUrl } from '@/presentation/services/urls/cashback';

export const CashbackBalanceSEO = () => {
  const url = getCashbackBalanceUrl();
  const canonical = getCanonicalUrl(url);

  return <SEO canonical={canonical} />;
};
