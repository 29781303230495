import { styled } from 'styled-components';
import {
  BodyHeavy,
  H3Heavy,
  MediaQuery,
  TextSkeleton,
  Theme,
} from '@pelando/components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 24px 0;
    gap: 8px;
  }
`;

export const Title = styled.h2`
  ${BodyHeavy}

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H3Heavy}
  }
`;

export const TitleSkeleton = styled(TextSkeleton)`
  &&& {
    height: 20px;
    width: 130px;
    min-width: 130px;
    min-height: 20px;

    @media ${MediaQuery.SCREEN_MD_UP} {
      height: 24px;
      width: 162px;
      min-width: 162px;
      min-height: 24px;
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -16px;

  li {
    padding: 0 16px;
    border-bottom: 1px solid rgb(${Theme.colors.Golf});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 0;

    li {
      padding: 0;
    }
  }
`;

export const CashbackItemButton = styled.button`
  display: contents;
`;
