import { Icon } from '@pelando/components';
import { CircleInfo } from '@pelando/fontawesome/icons';
import { CashbackTip } from '@/domain/entities/Cashback';
import { Container, TipText } from './style';

export const CashbackTipItem = ({ question, answer }: CashbackTip) => (
  <Container>
    <Icon icon={CircleInfo} style={{ fontSize: 20 }} />
    <TipText>
      <strong>{question}</strong> {answer}
    </TipText>
  </Container>
);
