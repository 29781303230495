import { useEffect, useState } from 'react';
import type { BehaviorSubject, Observable } from 'rxjs';

export function useApiCache<Cache = unknown>(
  cache$: Observable<Cache | undefined> | BehaviorSubject<Cache | undefined>
) {
  const [value, updateValue] = useState<Cache | undefined>(
    (cache$ as BehaviorSubject<Cache | undefined>).value
  );
  useEffect(() => {
    const subscription = cache$.subscribe(updateValue);
    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
}
