import { IconDefinition } from '@pelando/fontawesome';
import { Colors } from '@pelando/components';
import {
  ComputerSpeaker,
  GamepadModern,
  Couch,
  ClothesHanger,
  Books,
  PrescriptionBottleMedical,
  CartShopping,
  PersonBiking,
  SackDollar,
  Wallet,
  BabyCarriage,
  Moped,
  Plane,
  Car,
  Shovel,
  Router,
} from '@pelando/fontawesome/icons';

export const enum Categories {
  Eletronics = 'eletronicos',
  Game = 'games-e-pc-gamer',
  Home = 'casa-e-cozinha',
  Fashion = 'moda',
  Book = 'livros-filmes-e-musica',
  HealthAndBeauty = 'saude-e-beleza',
  Market = 'supermercado',
  Sports = 'esportes-e-exercicios',
  Free = 'gratis',
  Finances = 'financas',
  Baby = 'familia-e-criancas',
  Service = 'delivery-servicos-e-assinaturas',
  Trips = 'viagens',
  Automotive = 'carros-e-motos',
  Tools = 'ferramentas-e-jardim',
  Internet = 'planos-de-internet-tv-celular-e-fixo',
}

export const IconNameByCategories: Record<Categories, IconDefinition> = {
  [Categories.Eletronics]: ComputerSpeaker,
  [Categories.Game]: GamepadModern,
  [Categories.Home]: Couch,
  [Categories.Fashion]: ClothesHanger,
  [Categories.Book]: Books,
  [Categories.HealthAndBeauty]: PrescriptionBottleMedical,
  [Categories.Market]: CartShopping,
  [Categories.Sports]: PersonBiking,
  [Categories.Free]: SackDollar,
  [Categories.Finances]: Wallet,
  [Categories.Baby]: BabyCarriage,
  [Categories.Service]: Moped,
  [Categories.Trips]: Plane,
  [Categories.Automotive]: Car,
  [Categories.Tools]: Shovel,
  [Categories.Internet]: Router,
};

export const ColorByCategories: Record<Categories, Colors> = {
  [Categories.Eletronics]: Colors.Violet,
  [Categories.Game]: Colors.Blue,
  [Categories.Home]: Colors.Pink,
  [Categories.Fashion]: Colors.Alien,
  [Categories.Book]: Colors.Tangerine,
  [Categories.HealthAndBeauty]: Colors.Violet,
  [Categories.Market]: Colors.Blue,
  [Categories.Sports]: Colors.Pink,
  [Categories.Free]: Colors.Alien,
  [Categories.Finances]: Colors.Tangerine,
  [Categories.Baby]: Colors.Violet,
  [Categories.Service]: Colors.Blue,
  [Categories.Trips]: Colors.Pink,
  [Categories.Automotive]: Colors.Alien,
  [Categories.Tools]: Colors.Tangerine,
  [Categories.Internet]: Colors.Violet,
};

export const IdByCategories: Record<Categories, string> = {
  [Categories.Eletronics]: '29',
  [Categories.Game]: '31',
  [Categories.Home]: '18',
  [Categories.Fashion]: '19',
  [Categories.Book]: '26',
  [Categories.HealthAndBeauty]: '28',
  [Categories.Market]: '117',
  [Categories.Sports]: '17',
  [Categories.Free]: '33',
  [Categories.Finances]: '163',
  [Categories.Baby]: '15',
  [Categories.Service]: '20',
  [Categories.Trips]: '22',
  [Categories.Internet]: '30',
  [Categories.Automotive]: '23',
  [Categories.Tools]: '99',
};
